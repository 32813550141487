@charset "utf-8";
.contact {
    header h1,
    header .button,
    header .gNaviWrap,
	.guideSec,
    .social,
    .blank_link {
        display: none;
    }
    header {
        min-height: 100px;
    }
    .header_sp {
        .navBtn, .cartBtn {
            @include max-screen(767px) {
                display: none;
            }
        }
    }
    #wrap {
        margin: 100px 0 0;
        @include max-screen(767px) {
            margin: 65px 0 0;
        }
    }
    &.step3 #wrap {margin-top: 173px;
        @include max-screen(767px) {margin-top: 65px;}
    }
	.inner {
		max-width: 800px;
		margin: 0 auto;
        @include max-screen(767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
	}
	.sec {
		padding-bottom: 70px;
		@include max-screen(767px) {
            padding-bottom: 20px;
        }
	}
	.intro {
		padding-top: 15px;
        .fz13 {font-size: 13px;}
		p {
			margin: 0 0 18px;
			@include max-screen(767px) {
	            margin: 0 0 5px;
	        }
			span {
				color: #D22522;
			}
			a {
				color: #00BBCC;
				text-decoration: underline;
			}
			&:last-child {
				@include max-screen(767px) {
		            font-size: 12px;
		        }
			}
		}
	}
	.img_step {
		padding: 25px 0 0;
		@include max-screen(767px) {
            padding: 15px 0 0;
        }
	}
	table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #DDDDDD;
        margin: 20px 0 25px;
        @include max-screen(767px) {
            display: block;
            border: none;
            margin: 25px 0 5px;
        }
        tbody {
            @include max-screen(767px) {
                display: block;
            }
        }
        tr {
            border-bottom: 1px solid #DDDDDD;
            @include max-screen(767px) {
                border: none;
                display: block;
            }
            &:last-child {
            	th {
            		background: #EBEBED;
            	}
            }
            th,td {
                padding: 10px 20px;
                @include max-screen(767px) {
                    display: block;
                    width: initial;
                    width: inherit;
                    margin: 0 -25px;
                }
            }
            th {
                width: 263px;
                background: #FAE9E9;
                text-align: left;
                font-weight: normal;
                border-left: 1px solid #DDDDDD;
                border-right: 1px solid #DDDDDD;
                &.vaT {vertical-align: top; padding-top: 15px;}
                @include max-screen(767px) {
                    border: none;
                    width: initial;
                    width: inherit;
                    padding: 5px 25px;
                    &.vaT {vertical-align: unset; padding-top: 5px;}
                }
                span {
                    color: #D22522;
                    display: inline-block;
                    margin-right: 5px;
                    // @media screen and (-webkit-min-device-pixel-ratio:0) {
                    //     width: 60px;
                    // }
                }
                em {
                    display: inline-block;
                	font-style: normal;
                    font-size: 14px;
                    margin-right: 5px;
                    // @media screen and (-webkit-min-device-pixel-ratio:0) {
                    //     width: 60px;
                    // }
                }
            }
            td {
                border-right: 1px solid #DDDDDD;
                @include max-screen(767px) {
                    border: none;
                    padding: 10px 25px;
                }
                input,
                textarea {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #DDDDDD;
                    background:  #FFF;
                    padding: 0 10px;
                }
                input::-moz-placeholder {}
                .selectDiv {
                	position: relative;
    				display: inline-block;
    				&:after {
    					width: 10px;
					    height: 10px;
					    content: "";
					    display: block;
					    background: url(../img/common/icon/icon_select.svg);
					    position: absolute;
					    top: 50%;
					    right: 10px;
					    margin-top: -5px;
    				}
                }
                textarea {
                	height: 175px;
                }
                select {
                	-webkit-appearance: none;
				    -moz-appearance: none;
				    -ms-appearance: none;
				    appearance: none;
				    border: 1px solid #DDDDDD;
				    height: 40px;
				    padding: 0 10px;
				    font-size: 15px;
				    width: 328px;
				    background: #fff;
				    position: relative;
                }
                div.address {
                    margin: 0 0 10px;
                    @include max-screen(767px) {
                        margin: 0 0 5px;
                    }
                    .size_2 {
                        width: 140px;
                    }
                    span {
                        font-size: 12px;
                    }
                    span.txt_sm {
                        @include max-screen(767px) {
                            display: block;
                            padding: 5px 0 0 17px;
                        }
                    }
                }
                div.code {
                    input {
                        width: 350px;
                        @include max-screen(767px) {
                            width: 100%;
                        }
                    }
                    span {
                        display: block;
                        padding: 5px 0 5px;
                        @include max-screen(767px) {
                            font-size: 12px;
                        }
                    }
                    &:last-child {
                        span {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .page_btn {
        border: 0;
        clear: both;
        margin: 45px auto 18px;
        @include max-screen(767px) {
            font-size: 15px;
            height: 45px;
            line-height: 45px;
            margin: 15px auto 10px;
        }

    }
    .contact_note {
        font-size: 13px;
    	@include max-screen(767px) {
            font-size: 12px;
        }
    }
    .contact_info {
        text-align: center;
        padding: 50px 0 0;
        font-size: 12px;
        @include max-screen(767px) {
            padding: 30px 0 0;
        }

    }
    &.step2 {
        .sec {
            @include max-screen(767px) {
                padding: 10px 0 20px;
            }
            .img_step {
            	margin: 0 0 37px;
            	@include max-screen(767px) {
					margin: 0 0 15px;
	            }
            }
            .note {
            	margin: 0 0 20px;
            	@include max-screen(767px) {

	            }
            }
            table {
                margin: 0 0 20px;
                tr {
                    @include max-screen(767px) {
                        border-bottom: 1px solid #8e8e8e;
                        padding: 10px 25px;
                        margin: 0 -15px;
                    }
                    th,td {
                    	padding: 17px 20px;

                    }

                    th {
                        background: #eaebec  ;
                        @include max-screen(767px) {
                            background: #FFF;
                            padding: 0 15px;
                        }
                        em {
                            font-style: normal;
                            position: relative;
                            @include max-screen(767px) {
                                padding-left: 20px;
                            }
                            &:before {
                                @include max-screen(767px) {
                                    display: block;
                                    content: "";
                                    width: 12px;
                                    height: 12px;
                                    background: #1E1E1E;
                                    position: absolute;
                                    left: 0;
                                    top: 1px;
                                }

                            }
                        }
                    }
                    td {
                        @include max-screen(767px) {
                            background: #FFF;
                            padding: 0 15px;
                        }
                    }
                }
            }
            .page_back {
                float: right;
                position: relative;
                margin: 0 0 60px;
                @include max-screen(767px) {
                    font-size: 11px;
                    margin: 0 0 20px;
                }
                &:before {
                    display: block;
                    content: "";
                    width: 7px;
                    height: 6px;
                    background: url(../img/common/icon/arrow_right02.svg) no-repeat;
                    background-size: 7px 6px;
                    position: absolute;
                    top: 7px;
                    left: -10px;
                }
            }
            .page_btn {
            	margin-bottom: 5px;
            }
        }
    }
    &.step3 {
        header h1,
        header .gNaviWrap,
        .guideSec,
        .social,
        .blank_link {
            display: block;
        }
        header .button {
            display: inline-block;
        }
        header {
            min-height: 100px;
        }
        .header_sp {
            .navBtn, .cartBtn {
                @include max-screen(767px) {
                    display: block;
                }
            }
        }
        .guideSec,
        .social,
        .blank_link {
            display: block;
        }
        .bread {
            background: none;
            padding-bottom: 0;
        }
        .sec {
            padding: 0 0 60px;
            @include max-screen(767px) {
                padding: 0 0 30px;
            }
            .img_step {
                margin: 0 0 35px;
                @include max-screen(767px) {
                    margin: 0 0 25px;
                }
            }
            h3 {
                font-size: 19px;
                color: #00BBCC;
                font-size: normal;
                text-align: center;
                @include max-screen(767px) {
                    margin: 0 0 15px;
                }
            }
            div.login_alert {
                line-height: 28px;
                margin: 0 0 18px;
                @include max-screen(767px) {
                    line-height: 25px;
                }
                p {
                    &:first-child {
                        @include max-screen(767px) {
                            // text-align: center;
                        }
                    }
                }
            }
            div.contact_info {
                line-height: 27px;
                margin: 0 0 70px;
                text-align: left;
                padding: 0;
                font-size: 14px;
                @include max-screen(767px) {
                    line-height: 25px;
                    margin: 0 0 30px;
                    font-size: 14px;
                }
            }
            .btn_back {
                width: 240px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                border: 1px solid #1E1E1E;
                margin: 0 auto;
                font-size: 15px;
                display: block;
                position: relative;
                @include max-screen(767px) {
                    width: 200px;
                }
                &:before {
                    display: block;
                    content: "";
                    width: 8px; height: 8px; border-top: 1px solid #1e1e1e; border-right: 1px solid #1e1e1e; @include transform_c(rotate(-135deg));
                    position: absolute; top: 50%; left: 15px; margin-top: -5px;
                    // width: 12px;
                    // height: 12px;
                    // background: url(../img/common/icon/arrow_left_black.svg);
                    // position: absolute;
                    // top: 50%;
                    // left: 10px;
                    // margin-top: -6px;
                }
            }
        }
    }
}








