@charset 'UTF-8';

.cart {
	// background: url(../img/cart/cart_list.jpg) center -6px no-repeat;
	// > * {opacity: 0.5;}
	@include max-screen(767px) {
		.cartBtn {display: none;}
	}
	.container {max-width: 1000px; padding: 0 0; margin: 0 auto;
		@include max-screen(767px) {padding: 0;}
	}
	.buttonCart {text-align: center;
		@include max-screen(767px) {padding: 0 15px;}
		.btn {display: inline-block; width: 500px; line-height: 40px; border: 1px solid #1e1e1e; color: #1e1e1e; font-size: 15px;
			@include max-screen(767px) {width: 100%; font-size: 14px; line-height: 38px;}
			&.btnColor {line-height: 62px; color: #fff; background: #00bbcc; border: 0; margin-bottom: 11px; font-size: 18px;
				@include max-screen(767px) {font-size: 15px; line-height: 45px; margin-bottom: 7px;}
			}
		}
	}
	.blockListProduct {padding-bottom: 59px;
		@include max-screen(767px) {padding-bottom: 40px;}
		.barTitle {background: #f0f5f6; margin-bottom: 36px; margin-left: -10px; margin-right: -10px; padding: 0 10px;
			@include max-screen(767px) {padding: 0 25px; margin-bottom: 10px;}
			h2 {font-size: 20px; line-height: 54px;
				@include max-screen(767px) {font-size: 14px; font-weight: bold; line-height: 45px;}
			}
		}
		.introCart {line-height: 25px; margin-bottom: 22px;
			@include max-screen(767px) {padding: 0 15px; margin-bottom: 10px;}
		}
		.listProductInCart {border-top: 1px solid #d2d2d2;
			.eachProduct {border-bottom: 1px solid #d2d2d2;
				.innerE {padding: 45px 0 55px; position: relative;
					@include max-screen(767px) {padding: 20px 15px;}
					.deleteProductInCart {position: absolute; top: 40px; right: 0; color: #00bbcc; text-decoration: underline;
						@include max-screen(767px) {font-size: 12px; right: 15px; top: 20px;}
					}
					.titlePro {margin-bottom: 15px;
						h4 {font-size: 14px; font-weight: bold;
							@include max-screen(767px) {padding-right: 50px;}
						}
						.productCode {font-size: 11px; color: #787878;}
					}
					.clearfix {
						.imgProduct {float: left; width: 220px; margin-right: 30px;
							@include max-screen(767px) {width: 80px; margin-left: -15px;margin-right: 10px;}
						}
						.detailProCart {overflow: hidden;
							.productCode {overflow: hidden; font-size: 12px; color: #787878;}
							h4 {margin: 15px 0; font-size: 26px; letter-spacing: 1.4px;}
							.pricePro01 {line-height: 30px;
								@include max-screen(767px) {line-height: 25px; font-size: 12px;}
							}
							.pricePro02 {line-height: 30px;
								@include max-screen(767px) {line-height: 25px; font-size: 12px;}
							}
							.quantiryProduct {margin-top: 10px; font-size: 0; letter-spacing: 0;
								@include max-screen(767px) {letter-spacing: 1px;}
								label {display: inline-block; vertical-align: middle; margin-right: 23px; font-size: 14px;
									@include max-screen(767px) {font-size: 12px; margin-right: 10px;
										&.labelQuantity {margin-right: 31px;}
									}
								}
								.selectDiv {display: inline-block; vertical-align: middle; position: relative;
									select {-webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; appearance: none; border: 1px solid #8e8e8e; height: 38px; padding: 0 10px; font-size: 15px; width: 112px; background: #fff; font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
										@include max-screen(767px) {font-size: 13px; width: 80px; height: 35px;}
									}
									&:after {width: 5px; height: 5px; content: ""; display: block; border-bottom: 1px solid #8e8e8e; border-right: 1px solid #8e8e8e; @include transform_c(rotate(45deg)); position: absolute; top: 50%; right: 10px; margin-top: -3px;}
								}
							}
							.selectDivEveryMonth {display: inline-block; vertical-align: middle; margin-left: 55px;
								@include max-screen(767px) {margin-left: 0; margin-top: 10px; letter-spacing: 0;}
								label {display: inline-block; vertical-align: middle;}
								.selectDiv {display: inline-block; vertical-align: middle; margin-right: 30px;
									@include max-screen(767px) {margin: 0; width: calc(100% - 58px);}
									select {width: 172px;
										@include max-screen(767px) {font-size: 13px; height: 35px; width: 100%;}
									}
								}
								.linkToGuide {display: inline-block; vertical-align: middle; font-size: 14px; text-decoration: underline;
									@include max-screen(767px) {margin-left: 57px; margin-top: 10px; font-size: 11px; line-height: 1;}
								}
							}
						}
					}
				}
			}
		}
		.sunmaryCart {background: #f6f6f6; height: 80px; text-align: right; padding: 0 25px; margin-bottom: 58px;
			@include max-screen(767px) {padding: 11px 15px; height: auto; margin-bottom: 15px;}
			.subPrice {font-size: 16px; display: inline-block; line-height: 80px; margin-right: 30px; vertical-align: middle;
				span:first-child {margin-right: 10px;
					@include max-screen(767px) {font-size: 12px;}
				}
				.price {font-weight: normal;}
				@include max-screen(767px) {line-height: 1; margin-right: 0; margin-bottom: 10px; display: block; text-align: right;}
			}
			.totalPrice {font-size: 16px; display: inline-block; line-height: 80px;
				.price {font-size: 32px; font-weight: normal;
					@include max-screen(767px) {font-size: 23px;}
				}
				span:first-child {margin-right: 10px;
					@include max-screen(767px) {font-size: 12px;}
				}
				span:last-child {font-size: 20px;
					@include max-screen(767px) {font-size: 13px;}
				}
				@include max-screen(767px) {line-height: 1; display: block; text-align: right;}
			}
		}
	}
	.blockTrend {margin-bottom: 100px;
		@include max-screen(767px) {margin-bottom: 20px;}
		.barTitle {background: #a1b2ba; margin-bottom: 20px; margin-left: -10px; margin-right: -10px; padding: 0 10px;
			h2 {font-size: 20px; color: #fff; text-align: center; letter-spacing: 2px; font-weight: normal; line-height: 80px;}
			@include max-screen(767px) {margin-bottom: 0;
				h2 {font-size: 15px; line-height: 53px;}
			}
		}
		.listTrendProduct {margin-left: -11px; margin-right: -11px; margin-bottom: 60px;
			@include max-screen(767px) {margin: 0 0 30px;}
			.eachProduct {float: left; width: 20%; padding: 0 11px;
				@include max-screen(767px) {float: none; width: auto; padding: 15px 15px; border-bottom: 1px solid #bbbbbb;}
				.innerE {position: relative; padding: 20px 0;
					@include max-screen(767px) {padding: 0;}
					.clearfix {
						.img {
							img {display: block; max-height: 190px; margin: 0 auto;}
							@include max-screen(767px) {float: left; width: 80px; margin-left: -25px;}
						}
						.introProduct {text-align: center; margin-top: 15px;
							@include max-screen(767px) {text-align: left; overflow: hidden; margin-top: 0;}
							.tagProduct {font-size: 10px; color: #fff; display: inline-block; padding: 0 10px; border-radius: 10px;
								@include max-screen(767px) {font-size: 10px;
									&:empty {display: none;}
								}
								&.tagPurple {background: #7189c4;}
								&.tagOrange {background: #e88400;}
							}
							.categoryProduct {font-size: 12px; color: #00bbcc;
								@include max-screen(767px) {font-size: 11px;
									span {display: inline-block; margin-right: 10px;}
									.spTag {display: inline-block; font-style: normal; color: #fff; border-radius: 10px; font-size: 10px; padding: 0 10px; line-height: 16px;
										&.tagPurple {background: #7189c4;}
										&.tagOrange {background: #e88400;}
									}
								}
							}
							.titleProduct {font-size: 15px; padding: 0 15px;
								@include max-screen(767px) {font-size: 14px; padding: 0;
									h4 {font-weight: bold; line-height: 1.5; margin: 5px 0;}
								}
							}
							.priceProduct {font-size: 15px;
								@include max-screen(767px) {font-size: 11px;
									p {display: inline-block; vertical-align: middle;}
								}
								.smallPrice {font-size: 12px;
									@include max-screen(767px) {font-size: 11px;}
								}
							}
						}
					}
					.buttonArea {position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.7); transition: 0.3s ease all; display: table; opacity: 0;
						@include max-screen(767px) {position: relative; width: auto; height: auto; display: block; opacity: 1; background: transparent; margin-top: 15px;}
						.tCell {display: table-cell; vertical-align: middle; height: 100%;
							@include max-screen(767px) {display: block;}
						}
						.btn {font-size: 15px; color: #00bbcc; border: 1px solid #00bbcc; width: 156px; line-height: 48px; display: block; margin: 0 auto 10px; background: #fff; text-align: center;
							@include max-screen(767px) {display: inline-block; line-height: 38px; margin: 0; font-size: 14px;}
							@include max-screen(330px) {width: 49%;}
							&.btnColor {background: rgba(0,187,204,0.85); color: #fff; border-color: transparent; margin-bottom: 0;
								span {display: inline-block; background: url(../img/cart/icoCart.svg) left center no-repeat; padding-left: 25px;}
								@include max-screen(767px) {float: right;}
							}
						}
					}
					&.canHover:hover {
						.buttonArea {opacity: 1;}
					}
				}
			}
		}
		.buttonTrend {text-align: center;
			@include max-screen(767px) {padding: 0 15px;}
			.btn {display: inline-block; font-size: 15px; line-height: 40px; border: 1px solid #1e1e1e; width: 500px;
				@include max-screen(767px) {width: 100%; font-size: 14px; line-height: 38px;}
			}
		}
	}

	.imgStep {margin-bottom: 30px;
		@include max-screen(767px) {margin: -10px -10px 10px;
			img {display: block; width: 100%;}
		}
	}

	.block {padding-bottom: 59px;
		@include max-screen(767px) {padding-bottom: 30px;
			.container {padding: 0 15px;}
		}
		.barTitle {background: #f0f5f6; margin-bottom: 36px; margin-left: -10px; margin-right: -10px; padding: 0 10px;
			@include max-screen(767px) {margin-bottom: 10px;}
			h2 {font-size: 20px; line-height: 54px;
				@include max-screen(767px) {line-height: 45px; font-size: 14px; font-weight: bold;}
			}
		}
	}

	&__step01 {
		.header02 {height: 106px;
			@include max-screen(767px) {height: 62px;}
		}
        .bread {
            margin-bottom: 60px;
            @include max-screen(767px) {
                margin-bottom: 30px;
            }
        }
        #wrap {margin-top: 100px;
        	@include max-screen(767px) {margin-top: 62px;}
        }
		.block {
			.textN {line-height: 25px;
				@include max-screen(767px) {font-size: 12px; line-height: 1.5;}
			}
			.text12 {font-size: 12px; line-height: 2;}
			.taJ {text-align: justify;}
			.radioCheck {
				label {font-size: 18px; margin-left: 5px; font-weight: normal;}
				input:checked,
				input:not(:checked) {
				    position: absolute;
				    left: -9999px;
				}
				input:checked + label,
				input:not(:checked) + label
				{
				    position: relative;
				    padding-left: 28px;
				    cursor: pointer;
				    line-height: 20px;
				    display: inline-block;
				    color: #1e1e1e;
				}
				input:checked + label:before,
				input:not(:checked) + label:before {
				    content: '';
				    position: absolute;
				    left: 0;
				    top: 0;
				    width: 18px;
				    height: 18px;
				    border: 1px solid #1e1e1e;
				    border-radius: 100%;
				    background: #fff;
				}
				input:checked + label:after,
				input:not(:checked) + label:after {
				    content: '';
				    width: 12px;
				    height: 12px;
				    background: #1e1e1e;
				    position: absolute;
				    top: 3px;
				    left: 3px;
				    border-radius: 100%;
				    -webkit-transition: all 0.2s ease;
				    transition: all 0.2s ease;
				}
				input:not(:checked) + label:after {
				    opacity: 0;
				    -webkit-transform: scale(0);
				    transform: scale(0);
				}
				input:checked + label:after {
				    opacity: 1;
				    -webkit-transform: scale(1);
				    transform: scale(1);
				}
				@include max-screen(767px) {
					input:checked+label,
					input:not(:checked)+label {padding-left: 20px; font-size: 14px; line-height: 1; margin-left: 0;}
					input:checked+label:before,
					input:not(:checked)+label:before {width: 14px; height: 14px;}
					input:checked+label:after,
					input:not(:checked)+label:after {width: 8px; height: 8px;}
				}
			}
			.selectInline {display: inline-block; vertical-align: middle; position: relative;
				@include max-screen(767px) {display: block; margin-top: 5px;}
				select {-webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; appearance: none; border: 1px solid #dddddd; height: 38px; padding: 0 10px; font-size: 15px; width: 400px; background: #fff; font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
					@include max-screen(767px) {width: 100%;}
				}
				&:after {width: 5px; height: 5px; content: ""; display: block; border: 10px solid #8e8e8e; position: absolute; top: 50%; right: 10px; margin-top: -3px; border-color: #8e8e8e transparent transparent transparent; border-width: 8px 6px;}
			}
			.boxCheckShipping {margin-top: 20px; border-top: 1px solid #d2d2d2;
				@include max-screen(767px) {padding: 0 15px; margin-left: -15px; margin-right: -15px;}
				.eachShipping {padding-top: 30px;
					@include max-screen(767px) {padding-top: 20px;}
					.addressCustomer {padding-top: 20px;
						@include max-screen(767px) {padding-top: 10px;}
						.tableForm {width: 800px;
							th, td {padding: 10px 24px 9px; border: 1px solid #dddddd; text-align: left;}
							th {background: #fae9e9; font-weight: normal; width: 260px;}
							td {}
							input {display: block; width: 100%; height: 40px; border: 1px solid #dddddd; padding: 0 10px;
								&#shipping_zipcode {display: inline-block; width: 136px; margin-bottom: 10px; margin-left: 5px;
									@include max-screen(767px) {margin-bottom: 5px;}
								}
							}
							.noticeAddress {font-size: 12px; margin-left: 12px;}
							@include max-screen(767px) {
								display: block; width: auto; margin-left: -25px; margin-right: -25px;
								tbody, tr {display: block; width: 100%;}
								th, td {display: block; width: 100%; height: auto; padding: 0 25px; border: 0;}
								th {line-height: 32px;}
								td {padding-top: 14px; padding-bottom: 14px;}
								.noticeAddress {display: block; padding-left: 23px; margin-left: 0; margin-bottom: 5px; line-height: 1.4;}
							}
						}
					}
				}
			}
			.delivdate {padding: 35px 0;
				@include max-screen(767px) {padding: 20px 0;}
				.deliv_date {margin-bottom: 20px;
					@include max-screen(767px) {margin-bottom: 10px;}
				}
				.deliv_time {}
				label {display: inline-block; vertical-align: middle; width: 100px;}
			}
			.textPoint {font-size: 0; margin-bottom: 10px;
				span {font-size: 18px; display: inline-block; vertical-align: middle;
					@include max-screen(767px) {font-size: 13px;}
					&.bg {background: #a1b2ba; color: #fff; font-size: 15px; line-height: 36px; padding: 0 20px; margin-right: 10px;
						@include max-screen(767px) {font-size: 11px; line-height: 25px;}
					}
					&.point {font-size: 28px;
						@include max-screen(767px) {font-size: 19px;}
					}
				}
			}
			.highlight {color: #33c9d6;
				@include max-screen(767px) {font-size: 12px;}
			}
			hr.line {margin: 30px 0; border: 1px solid #bbbec2;
				@include max-screen(767px) {margin: 15px 0; border-bottom: 0;}
			}
			.formLnNumPoint {margin: 15px 0;
				@include max-screen(767px) {margin: 10px 0;}
				#numPoint {border: 1px solid #dddddd; height: 40px; padding: 0 15px; display: inline-block; vertical-align: middle; width: 150px;}
				label[for="numPoint"] {display: inline-block; vertical-align: middle;}
			}
			.imgCards {display: block; margin: 20px 0 25px;
				@include max-screen(767px) {width: 295px; margin: 10px 0 15px;}
			}
			.boxCardInformation {background: #f6f6f6; padding: 30px 35px 35px;
				@include max-screen(767px) {padding: 15px; margin: 0 -15px;}
				.formLn {font-weight: bold; margin-bottom: 10px;
					&:last-child {margin-bottom: 0;}
					label {display: inline-block; vertical-align: middle; width: 190px; font-weight: normal;
						span {color: #d22522;}
						@include max-screen(767px) {display: block;}
					}
					input {display: inline-block; vertical-align: middle; background: #fff; border: 1px solid #dddddd; height: 40px; width: 120px; margin: 0 2px; padding: 0 10px;
						&.inputCardnum {
							@include max-screen(767px) {width: 78px; margin: 0; height: 30px;
								@include max-screen(413px) {width: 68px;}
								@include max-screen(374px) {width: 54px;}
							}
						}
						&.cardName {width: 260px;
							@include max-screen(767px) {width: 100%; height: 40px;}
						}
						&.cardSCode {width: 160px;
							@include max-screen(767px) {width: 95px; height: 40px;}
						}
					}
					.dash {color: transparent; position: relative;
						&:after {content: ""; display: block; width: 10px; height: 2px; background: #1e1e1e; position: absolute; top: 50%; left: 50%; @include transform_c(translate(-50%,-50%));
							@include max-screen(767px) {width: 5px;}
						}
					}
					.slash {
						@include max-screen(767px) {line-height: 40px; display: inline-block; vertical-align: bottom;}
					}
					.selectInline {margin-left: 2px;
						@include max-screen(767px) {width: 95px; display: inline-block;}
						select {width: 160px;
							@include max-screen(767px) {width: 100%;}
						}
					}
					&.formLnConfirm {text-align: center; margin-top: 40px;
						@include max-screen(767px) {text-align: center;}
						input {width: auto; margin: 0;}
						label {font-weight: bold; font-size: 16px; width: auto; letter-spacing: 1px;
							@include max-screen(767px) {font-size: 15px; display: inline-block; padding-left: 15px;}
							@include max-screen(374px) {font-size: 13px;}
						}
					    .wrap_checkbox {position: relative; padding-left: 35px; line-height: 24px;
					    	@include max-screen(767px) {line-height: 20px;}
					    	&:hover input ~ .checkmark {background-color: #FFF;}
					    	input { position: absolute; opacity: 0; cursor: pointer; }
					    	input:checked ~ .checkmark {background-color: #FFF;}
					    	input:checked ~ .checkmark:after {display: block;}
						    .checkmark { position: absolute; top: 0; left: 0; height: 24px; width: 24px; background-color: #FFF; border: 2px solid #DDDDDD;
						        @include max-screen(767px) { height: 20px; width: 20px; top: 0; }
						        &:after { content: ""; position: absolute; display: none; }
						    }
						    .checkmark:after { left: 7px; top: 3px; width: 6px; height: 14px; border: solid #FF0000; border-width: 0 3px 3px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg);
						        @include max-screen(767px) { left: 5px; top: 1px; width: 6px; height: 12px; border: solid #FF0000; border-width: 0 2px 3px 0; }
						    }
					    }
					}
				}
			}
			.eachPayment {margin-bottom: 40px;
				&:last-child {margin-bottom: 0;}
				@include max-screen(767px) {margin-bottom: 30px;
					&:last-child {margin-bottom: 0;}
				}
				.radioCheck {margin-bottom: 10px;
					@include max-screen(767px) {margin-bottom: 0;}
				}
			}
			.container_buttonCart {margin-top: -50px;}
		}
		footer {margin-top: 120px;
			@include max-screen(767px) {margin-top: 60px;}
			.guideSec {display: none;}
			.social {display: none;}
			.blank_link {display: none;}
		}
	}

	&__step02 {
		.header02 {height: 106px;
			@include max-screen(767px) {height: 62px;}
		}
        .bread {
            margin-bottom: 60px;
            @include max-screen(767px) {
                margin-bottom: 30px;
            }
        }
        #wrap {margin-top: 100px;
        	@include max-screen(767px) {margin-top: 62px;}
        }
		@include max-screen(767px) {
			.navBtn {display: none;}
		}
		.blockPayment {padding-bottom: 50px;}
		.blockListProduct {
			.barTitle {margin-bottom: 0;
				@include max-screen(767px) {
					.container {}
				}
			}
			.listProductInCart {border-top: 0;
				.eachProduct .innerE {padding: 30px 0;
					@include max-screen(767px) {padding: 20px 15px;}
					.clearfix .detailProCart .quantiryProduct {font-size: 14px; margin: 0; line-height: 30px;}
				}
			}
			@include max-screen(767px) {padding-bottom: 20px;
				.container {}
			}
		}
		.tableFormConfirm {width: 800px; margin: 0 auto;
			th, td {height: 60px; padding: 17px 15px; border: 1px solid #dddddd; font-weight: normal;}
			th {background: #ebebed; text-align: left; width: 260px;}
			td {}
			em {display: none;}
			@include max-screen(767px) {width: 100%; display: block;
				tbody, tr {display: block; width: 100%;}
				th, td {display: block; width: 100%; border: 0; padding: 8px 15px; height: auto;}
				th {background: #fff; padding-bottom: 0}
				td {border-bottom: 1px solid #8e8e8e; padding-top: 0;}
				em {display: inline-block; margin-right: 5px; font-style: normal;}
			}
		}
		@include max-screen(767px) {
			.block.block_tableConfirm {padding-bottom: 40px;
				.container.container_tableConfirm {padding: 0;}
			}
		}
		footer {margin-top: 120px;
			@include max-screen(767px) {margin-top: 60px;}
			.guideSec {display: none;}
			.social {display: none;}
			.blank_link {display: none;}
		}
	}

	&__step03 {
		.blockFinishCart {padding: 50px 0 80px;
			@include max-screen(767px) {padding: 40px 0;}
			.txt01 {font-size: 26px; color: #00bbcc; text-align: center; letter-spacing: 4px;
				@include max-screen(767px) {font-size: 19px; letter-spacing: 2px;}
			}
			.txtN {max-width: 800px; margin: 30px auto 0;
				@include max-screen(767px) {padding: 0 15px;}
			}
		}
		.btnArea {text-align: center; margin-bottom: 100px;
			@include max-screen(767px) {margin-bottom: 40px;}
			a {display: inline-block; width: 240px; line-height: 48px; border: 1px solid #1e1e1e; font-size: 15px;
				span {display: block; position: relative;
					&:before {content: ""; display: block; border-top: 1px solid #1e1e1e; border-right: 1px solid #1e1e1e; width: 5px; height: 5px; position: absolute; top: 50%; left: 10px; margin-top: -4px; @include transform_c(rotate(-135deg));}
				}
			}
		}
	}
}