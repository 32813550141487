.mypage {
    .gNaviMypage {
        display: block;
    }
    #wrap {
        margin: 214px 0 0;
        @include max-screen(767px) {
            margin: 100px 0 0;
        }
    }
    .bHead {
        background: #A1B2BA;
    }
    .inner {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        @include max-screen(767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .page_title {
        background: rgba(197, 217, 221, 0.3);
        line-height: 20px;
        font-size: 20px;
        padding: 20px 0;
        margin: 0 -10px 40px;
        @include max-screen(767px) {
            margin: 0 -10px 30px;
            padding: 18px 10px;
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
        }
    }
    &.mypage_menu {
        .gNaviMypage {
            display: none;
        }
        #wrap {
            margin: 173px 0 0;
            @include max-screen(767px) {
                margin: 100px 0 0;
            }
        }
        .info {
            .info_name {
                margin: 0 0 20px;
                @include max-screen(767px) {
                    margin: 0 0 10px;
                    padding-left: 15px;
                }
            }
            .info_box {
                border: 2px solid #00BBCC;
                padding: 10px 15px;
                p {
                    span {
                        display: inline-block;
                        background: #A1B2BA;
                        padding: 3px 5px;
                        color: #FFF;
                        font-size: 11px;
                    }
                    em {
                        font-size: 19px;
                        padding-left: 10px;
                        font-style: normal;
                        position: relative;
                        top: 2px;
                        small {
                            font-size: 13px;
                        }
                    }
                }
                .note_point {
                    font-size: 12px;
                    padding: 10px 0 0;
                }
            }
        }
        .menu {
            padding: 60px 0 50px;
            @include max-screen(767px) {
                padding: 30px 0 0;
            }
            .title_menu {

            }
            .menu_wrap {
                ul {
                    @include clearfix();
                    margin: 0 -5px;
                    @include max-screen(767px) {
                        margin: 0
                    }
                    li {
                        padding: 0 5px;
                        float: left;
                        width: 50%;
                        margin: 0 0 10px;
                        @include max-screen(767px) {
                            float: none;
                            padding: 0;
                            width: 100%;
                        }
                        a {
                            display: block;
                            font-size: 17px;
                            font-weight: 600;
                            color: $colortxt1;
                            text-align: center;
                            padding: 15px 10px;
                            border: 1px solid $colortxt1;
                            position: relative;
                            @include max-screen(767px) {
                                font-size: 15px;
                                padding: 10px 10px;
                            }
                            &:after {
                                display: block;
                                content: "";
                                width: 10px;
                                height: 12px;
                                background: url(../img/common/icon/arrow_right_blue.svg) no-repeat;
                                position: absolute;
                                top: 50%;
                                right: 8px;
                                margin-top: -3px;
                            }
                            span {
                                display: inline-block;
                                position: relative;
                            }
                        }
                        &:first-child {
                            a {
                                span {
                                    &:before {
                                        display: block;
                                        content: "";
                                        width: 18px;
                                        height: 18px;
                                        background: url(../img/common/icon/heart.svg) no-repeat;
                                        position: absolute;
                                        top: 50%;
                                        left: -27px;
                                        margin-top: -10px;
                                        @include max-screen(767px) {
                                            width: 13px;
                                            height: 12px;
                                            left: -20px;
                                            margin-top: -7px;
                                        }
                                    }
                                }
                            }
                        }
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    &.mypage_modify1 {
        .guideSec,
        .social,
        .blank_link {
            display: none;
        }
        /*
        header h1,
        .header .button,

        .gNaviWrap .my_menu .item1 {
            display: none;
        }


        .gNaviWrap {
            position: relative;
            top: -55px;
            min-height: auto;
        }

        .gNaviWrap:before {
            display: none;
        }
        .gNaviWrap .my_menu{
            width: 186px;
        }
        .gNaviWrap .my_menu li {
            width: 50%;
        }
        .gNaviWrap .my_menu li.item2 {
            border-left: 1px solid #d2d2d2;
        }

        .header02__phone {
            display: block;
            position: static;
            float: right;
            padding-right: 50px;
            padding-top: 17px;
        }

        .header_sp .navBtn,
        .header_sp .cartBtn {
            display: none;
        }

        .gNaviMypage {
            position: relative;
            top: -40px;
        }
        */
        header {
            min-height: 100px;
            height: 100px;
        }
        #wrap {
            margin: 214px 0 0;
            @include max-screen(767px) {
                margin: 100px 0 0;
            }
        }
        .bread {
            margin-bottom: 25px;
            @include max-screen(767px) {
                margin-bottom: 18px;
            }
        }
       .page_title {
            @include max-screen(767px) {
                margin-bottom: 20px;
            }
        }
        .sec_form {
            padding: 0 0 73px;
            @include max-screen(767px) {
                padding: 0 0 20px;
            }

            .info {
                margin: 0 0 42px;
                font-size: 13px;
                @include max-screen(767px) {
                    font-size: 14px;
                     margin: 0 0 20px;
                }
            }
            .img_step {
                margin: 0 0 20px;
                @include max-screen(767px) {
                    margin: 0 0 30px;
                }
            }
            table {
                width: 100%;
                border-collapse: collapse;
                border: 1px solid #DDDDDD;
                margin: 0 0 60px;
                @include max-screen(767px) {
                    display: block;
                    border: none;
                    margin: 0 0 30px;
                }
                tbody {
                    @include max-screen(767px) {
                        display: block;
                    }
                }
                tr {
                    border-bottom: 1px solid #DDDDDD;
                    @include max-screen(767px) {
                        border: none;
                        display: block;
                    }
                    th,td {
                        padding: 10px 25px;
                        @include max-screen(767px) {
                            display: block;
                            width: initial;
                            width: inherit;
                            margin: 0 -15px;
                        }
                    }
                    th {
                        width: 263px;
                        background: #EBEBED;
                        text-align: left;
                        font-weight: normal;
                        border-left: 1px solid #DDDDDD;
                        border-right: 1px solid #DDDDDD;
                        @include max-screen(767px) {
                            border: none;
                            width: initial;
                            width: inherit;
                            padding: 5px 20px;
                            margin: 0 -25px;
                        }
                        &.th_code {
                            background: #FAE9E9;
                        }
                        span {
                            color: #D22522;
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                    td {
                        border-right: 1px solid #DDDDDD;
                        @include max-screen(767px) {
                            border: none;
                        }
                        input {
                            width: 100%;
                            height: 40px;
                            line-height: 40px;
                            border: 1px solid #DDDDDD;
                            background:  #FFF;
                            padding: 0 10px;

                        }
                        div.address {
                            margin: 0 0 10px;
                            @include max-screen(767px) {
                                margin: 0 0 5px;
                            }
                            .size_2 {
                                width: 135px;
                            }
                            span {
                                font-size: 12px;
                            }
                            span.txt_sm {
                                padding-left: 15px;
                                @include max-screen(767px) {
                                    display: block;
                                    padding: 5px 0 0 18px;
                                }
                            }
                        }
                        div.code {
                            input {
                                width: 350px;
                                @include max-screen(767px) {
                                    width: 100%;
                                }
                            }
                            span {
                                display: block;
                                padding: 5px 0 5px;
                                font-size: 12px;
                            }
                            &:last-child {
                                span {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            .page_btn {
                clear: both;
                @include max-screen(767px) {
                    font-size: 15px;
                    height: 45px;
                    line-height: 45px;
                }

            }
            .contact_info {
                text-align: center;
                padding: 110px 0 0;
                font-size: 12px;
                @include max-screen(767px) {
                    padding: 30px 0 0;
                }

            }
        }
    }
    &.mypage_modify2 {

        .sec_form {
            padding: 43px 0 70px;
            @include max-screen(767px) {
                padding: 0 0 20px;
            }
            .img_step {
                margin: 0 0 35px;
                @include max-screen(767px) {
                    margin: 0 0 30px;
                }
            }
            .note {
                padding: 0 0 25px;
                @include max-screen(767px) {
                    padding: 0 0 15px;
                }
            }
            table {
                margin: 0 0 20px;
                @include max-screen(767px) {
                    margin: 0 0 30px;
                }
                tr {
                    @include max-screen(767px) {
                        border-bottom: 1px solid #8e8e8e;
                        padding: 10px 0;
                        margin: 0 -15px;
                    }

                    th {
                        background: #EBEBED  ;
                        @include max-screen(767px) {
                            background: #FFF;
                            padding: 0 40px;
                        }
                        em {
                            font-style: normal;
                            position: relative;
                            @include max-screen(767px) {
                                padding-left: 20px;
                            }
                            &:before {
                                @include max-screen(767px) {
                                    display: block;
                                    content: "";
                                    width: 12px;
                                    height: 12px;
                                    background: #1E1E1E;
                                    position: absolute;
                                    left: 0;
                                    top: 1px;
                                }

                            }
                        }
                    }
                    td {
                        @include max-screen(767px) {
                            background: #FFF;
                            padding: 0 30px;
                        }
                    }
                }
            }
            .page_back {
                float: right;
                position: relative;
                margin: 0 0 60px;
                @include max-screen(767px) {
                    font-size: 11px;
                    margin: 0 0 30px;
                }
                &:before {
                    display: block;
                    content: "";
                    width: 7px;
                    height: 6px;
                    background: url(../img/common/icon/arrow_right02.svg) no-repeat;
                    background-size: 7px 6px;
                    position: absolute;
                    top: 9px;
                    left: -15px;
                    @include max-screen(767px) {
                        top: 7px;
                    }
                }
            }
            .contact_info {
                padding: 55px 0 0;
                @include max-screen(767px) {
                    padding: 30px 0 0;
                }
            }
        }
    }
    &.mypage_modify3 {
        .bread {
            background: none;
            padding-bottom: 0;
            ul {
                li {
                    &:last-child {
                        a {
                            text-decoration: none;
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .sec {
            padding: 28px 0 80px;
            @include max-screen(767px) {
                padding: 0 0 20px;
            }
            .img_step {
                margin: 0 0 35px;
                @include max-screen(767px) {
                    margin: 0 0 20px;
                }
            }

            div.login_alert {
                line-height: 28px;
                margin: 0 0 60px;
                @include max-screen(767px) {
                    line-height: 25px;
                    margin: 0 0 30px;
                }
            }
            .btn_back {
                width: 240px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                border: 1px solid #1E1E1E;
                margin: 0 auto;
                font-size: 15px;
                display: block;
                position: relative;
                @include max-screen(767px) {
                    width: 200px;
                }
                &:before {
                    display: block;
                    content: "";
                    width: 12px;
                    height: 12px;
                    background: url(../img/common/icon/arrow_left_black.svg);
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    margin-top: -6px;
                }
            }
        }
    }
    &.history {
        #wrap {

        }
        .page_title {
            @include max-screen(767px) {
                margin-bottom: 15px;
            }
            .secInner {
                @include max-screen(767px) {
                    padding: 0 15px;
                }
            }
        }
        .select_order {
            margin: 0 0 40px;
            @include max-screen(767px) {
                margin: 0 0 20px;
            }
            .secInner {
                @include max-screen(767px) {
                    padding: 0 15px;
                }
            }
            p {
                margin: 0 0 20px;
                @include max-screen(767px) {
                    margin: 0 0 10px;
                }
            }
            .selectDiv {
                position: relative;
                display: inline-block;
                @include max-screen(767px) {

                }
                &:after {
                    width: 5px;
                    height: 5px;
                    content: "";
                    display: block;
                    border-bottom: 1px solid #8e8e8e;
                    border-right: 1px solid #8e8e8e;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    margin-top: -3px;
                }
            }
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                appearance: none;
                border: 1px solid #8e8e8e;
                height: 50px;
                padding: 0 10px;
                font-size: 15px;
                width: 290px;
                background: #fff;
                position: relative;
                font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
                @include max-screen(767px) {
                    height: 38px;
                    width: 230px;
                    font-size: 13px;
                }
            }
        }
        .page_wrap {
            .order_box {
                margin: 0 0 80px;
                @include max-screen(767px) {
                    margin: 0 0 40px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                .order_box_title {
                    background: #F6F6F6;
                    margin: 0 -10px 30px;
                    padding: 6px 0;
                    @include max-screen(767px) {
                        padding: 9px 10px;
                        border-top: 1px solid #BBBBBB;
                        border-bottom: 1px solid #BBBBBB;
                        margin-bottom: 20px;
                    }
                    .secInner {
                        @include max-screen(767px) {
                            padding: 0 15px;
                        }
                    }
                    p,
                    span {
                        display: inline;
                        @include max-screen(767px) {
                            display: block;
                        }
                    }
                    p {
                        font-size: 14px;
                        font-weight: 600;
                        margin-right: 15px;
                        @include max-screen(767px) {
                            font-size: 11px;
                            margin-right: 0px;
                        }
                    }
                    span {
                        font-size: 12px;
                        color: #787878;
                        @include max-screen(767px) {
                            font-size: 11px;
                        }
                    }
                }
                .order_box_item {
                    .eachProduct {
                        border-bottom: 1px solid #d2d2d2;
                        padding-bottom: 50px;
                        margin-bottom: 50px;
                        position: relative;
                        @include max-screen(767px) {
                            padding: 0 15px 20px;
                            margin-bottom: 20px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                            border: none;
                        }
                        .deleteProductInCart {
                            position: absolute;
                            top: 50px;
                            right: 0;
                            color: #00bbcc;
                            z-index: 99;
                            text-decoration: underline;
                            @include max-screen(767px) {
                                top: auto;
                                bottom: 15px;
                                font-size: 12px;
                                right: 15px;
                            }
                        }
                        .titlePro {
                            margin-bottom: 15px;
                            @include max-screen(767px) {
                                margin-bottom: 7px;
                            }
                            h4 {
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }
                        .productCode {
                            font-size: 11px;
                            color: #787878;
                        }
                        .imgProduct {
                            float: left;
                            width: 220px;
                            @include max-screen(767px) {
                                width: 80px;
                                margin-left: -15px;
                            }
                        }
                        .detailProCart {
                            padding-left: 45px;
                            overflow-x: hidden;
                            position: relative;
                            @include max-screen(767px) {
                                padding-left: 10px;
                                position: static;
                                min-height: 255px;
                            }
                            .productCode {
                                font-size: 12px;
                                color: #787878;
                            }
                            h4 {
                                margin: -10px 0 20px;
                                font-size: 26px;
                                letter-spacing: 1.4px;
                                overflow: hidden;
                                @include max-screen(767px) {
                                    font-weight: 600;
                                    margin-top: 0;
                                }
                            }
                            .att {
                                line-height: 30px;
                                @include max-screen(767px) {
                                   line-height: 25px;
                                   font-size: 12px;
                                }
                            }


                        }
                        .quantiryProduct {
                            margin-top: 10px;
                            font-size: 0;
                            display: inline-block;
                            @include max-screen(767px) {
                               margin-top: 10px;
                                font-size: 0;
                                display: block;
                            }
                            label {
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 18px;
                                font-size: 14px;
                                @include max-screen(767px) {
                                   font-size: 12px;
                                    margin-right: 10px;
                                }
                            }
                            .selectDiv {
                                display: inline-block;
                                vertical-align: middle;
                                position: relative;
                                select {
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    -ms-appearance: none;
                                    appearance: none;
                                    border: 1px solid #8e8e8e;
                                    height: 38px;
                                    padding: 0 10px;
                                    font-size: 15px;
                                    width: 112px;
                                    background: #fff;
                                    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
                                    @include max-screen(767px) {
                                        font-size: 13px;
                                        width: 80px;
                                        height: 25px;
                                    }
                                    @include max-screen(374px) {
                                        width: 80px;
                                    }
                                }
                                &:after {
                                    width: 5px;
                                    height: 5px;
                                    content: "";
                                    display: block;
                                    border-bottom: 1px solid #8e8e8e;
                                    border-right: 1px solid #8e8e8e;
                                    -webkit-transform: rotate(45deg);
                                    -moz-transform: rotate(45deg);
                                    -ms-transform: rotate(45deg);
                                    -o-transform: rotate(45deg);
                                    transform: rotate(45deg);
                                    position: absolute;
                                    top: 50%;
                                    right: 10px;
                                    margin-top: -3px;
                                }
                            }
                        }
                        .order_action {
                            position: relative;
                            @include max-screen(767px) {
                                position: absolute;
                                bottom: 20px;
                                left: 0;
                                width: 100%;
                            }

                            .wrap_btn {
                                @include clearfix();
                                display: inline-block;
                                @include max-screen(767px) {
                                    position: static;
                                    left: 0;
                                    bottom: 0;
                                    width: 100%;
                                    padding: 0 10px;
                                    margin: 12px 0 0;
                                }
                                .quantiryProduct {
                                    float: left;
                                    @include max-screen(767px) {
                                        float: none;
                                        position: absolute;
                                        left: 75px;
                                        top: -35px;
                                    }
                                }
                                .wrap_add_action {
                                    display: inline-block;
                                    padding-top: 8px;
                                    padding-left: 20px;
                                    @include max-screen(767px) {
                                        width: 100%;
                                        padding: 0 5px;
                                    }
                                }
                                a {
                                    display: inline-block;
                                    height: 42px;
                                    line-height: 42px;
                                    text-align: center;
                                    @include max-screen(767px) {
                                        height: 38px;
                                        line-height: 38px;
                                    }
                                }
                                .addCart {
                                    width: 270px;
                                    background: rgba(0, 187, 204, 0.85);
                                    font-weight: 600;
                                    color: #FFF;
                                    float: left;
                                    font-size: 15px;
                                    margin-right: 10px;
                                    @include max-screen(767px) {
                                        float: right;
                                        width: 49%;
                                        margin-right: 0;
                                        font-size: 14px;
                                    }
                                    @include max-screen(374px) {
                                        font-size: 12px;
                                    }
                                    span {
                                        position: relative;
                                        padding-left: 30px;
                                        @include max-screen(767px) {
                                            padding-left: 25px;
                                        }
                                        &:before {
                                            display: block;
                                            content: "";
                                            width: 20px;
                                            height: 15px;
                                            background: url(../img/common/icon/icon_cart.svg);
                                            position: absolute;
                                            top: 50%;
                                            left: 0;
                                            margin-top: -7px;
                                        }
                                    }
                                }
                                .addLove {
                                    border: 1px solid #1E1E1E;
                                    color: #1E1E1E;
                                    width: 178px;
                                    font-size: 15px;
                                    float: right;
                                    @include max-screen(767px) {
                                        float: left;
                                        width: 49%;
                                        font-size: 14px;
                                    }
                                    @include max-screen(374px) {
                                        font-size: 12px;
                                    }
                                    span {
                                        position: relative;
                                        padding-left: 20px;
                                        &:before {
                                            display: block;
                                            content: "";
                                            width: 15px;
                                            height: 15px;
                                            background: url(../img/common/icon/icon_love.svg);
                                            position: absolute;
                                            top: 50%;
                                            left: 0;
                                            margin-top: -7px;
                                        }
                                    }
                                }
                            }
                            .wrap_buy {
                                @include clearfix();
                                background: rgba(30,30,30,0.07);
                                padding: 15px 30px;
                                margin: 15px 0 0;
                                @include max-screen(767px) {
                                    position: static;
                                    left: 0;
                                    bottom: 0;
                                    width: 100%;
                                    padding: 10px 15px;
                                    margin: 5px 0 0;
                                }
                                .wrap_quality {
                                    width: 60%;
                                    float: left;
                                    padding: 3px 0 0;
                                    @include clearfix();
                                    @include max-screen(767px) {
                                        width: 100%;
                                        float: none;
                                        text-align: center;
                                        padding-top: 0;
                                    }
                                    .quantiryProduct {
                                        margin-right: 25px;
                                        margin-top: 0;
                                        @include max-screen(767px) {
                                            display: inline-block;
                                        }
                                        @include max-screen(374px) {
                                            margin-right: 5px;
                                        }
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                            .wrap_btn_action {
                                width: 40%;
                                float: left;
                                @include max-screen(1024px) {
                                    width: 38%;
                                    padding-left: 2%;
                                }
                                @include max-screen(767px) {
                                    float: none;
                                    width: 100%;
                                    margin: 15px 0 0;
                                    padding-left: 0;
                                }
                                .subscribe {
                                    background: #1E1E1E;
                                    width: 100%;
                                    height: 42px;
                                    display: inline-block;
                                    color: #FFF;
                                    text-align: center;
                                    line-height: 42px;
                                    font-size: 15px;
                                    font-weight: 600;
                                    @include max-screen(767px) {
                                        font-size: 14px;
                                        height: 45px;
                                        line-height: 45px;
                                    }
                                    span {
                                        position: relative;
                                        padding-left: 25px;
                                        &:before {
                                            display: block;
                                            content: "";
                                            width: 16px;
                                            height: 16px;
                                            background: url(../img/common/icon/icon-subscribe.svg);
                                            position: absolute;
                                            top: 50%;
                                            left: 0;
                                            margin-top: -8px;
                                        }
                                    }
                                }
                                .change {
                                    background: #1E1E1E;
                                    width: 100%;
                                    height: 42px;
                                    display: inline-block;
                                    color: #FFF;
                                    font-size: 15px;
                                    font-weight: 600;
                                    text-align: center;
                                    line-height: 42px;
                                }
                            }

                        }
                    }
                }
                .order_box_detail {
                    margin: -20px 0 0;
                    @include max-screen(767px) {
                       margin: -10px 0 0;
                    }
                    h3 {
                        background: #A1B2BA;
                        font-size: 20px;
                        color: #FFF;
                        font-weight: normal;
                        padding: 10px 20px;
                        position: relative;
                        @include max-screen(767px) {
                            font-size: 14px;
                        }
                        @include max-screen(767px) {
                            &:before {
                                display: block;
                                content: "";
                                width: 14px;
                                height: 3px;
                                background: #FFF;
                                position: absolute;
                                top: 50%;
                                right: 15px;
                                margin-top: -1.5px;
                            }
                            &:after {
                                display: block;
                                content: "";
                                height: 14px;
                                width: 3px;
                                background: #FFF;
                                position: absolute;
                                top: 50%;
                                right: 20px;
                                margin-top: -7px;
                            }
                            &.active {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                    .row {
                        padding: 25px 0;
                        background: rgba(197, 217, 221, 0.3);
                        margin-left: 0;
                        margin-right: 0;
                        @include clearfix();
                        @include max-screen(767px) {
                            display: none;
                            padding: 0 0;
                        }
                    }
                    .col {
                        float: left;
                        border-right: 1px solid #9ca1a2;
                        padding-left: 30px;
                        @include max-screen(767px) {
                            float: none;
                            width: 100% !important;
                            border-right: none;
                            border-bottom: 1px solid #9ca1a2;
                            padding: 10px 15px;
                        }
                        &:nth-child(1) {
                            width: 42%;
                        }
                        &:nth-child(2) {
                            width: 36%;
                        }
                        &:nth-child(3) {
                            width: 22%;
                            border: none;
                        }
                        h4 {
                            font-weight: 600;
                            margin: 0 0 10px;
                            @include max-screen(767px) {
                                font-size: 12px;
                                margin: 0 0 8px;
                            }
                        }
                        table {
                            tr {
                                td {
                                    @include max-screen(767px) {
                                        font-size: 12px;
                                    }
                                    &:nth-child(2) {
                                        padding-left: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .paging {
            text-align: center;
            padding: 75px 0 50px;
            @include max-screen(767px) {
                border-top: 1px solid #BBBBBB;
                padding: 20px 0 10px;
                margin: 40px -15px 0;
            }

            a {
                display: inline-block;
                font-size: 16px;
                @include max-screen(767px) {
                    font-size: 13px;
                }
                &:hover {
                    text-decoration: underline;
                }
                &:nth-child(2) {
                    padding: 0 30px;
                }
            }
        }
    }
    &.regular {
        footer {
            @include max-screen(767px) {
                margin-top: 20px;
            }
        }
        .page_wrap {
            .note {
                margin: 0 0 40px;
                font-size: 16px;
                @include max-screen(767px) {
                    margin: 0 0 15px;
                }
                .secInner {
                    @include max-screen(767px) {
                        padding: 0 15px;
                    }
                }
            }
            .order_box {
                .order_box_item {
                    padding: 0 0 60px;
                    @include max-screen(767px) {
                        padding: 0 0 0;
                    }
                    .eachProduct {
                        padding: 50px 0 50px;
                        margin-bottom: 0;
                        @include max-screen(767px) {
                            padding: 20px 15px 55px;

                        }
                        &:first-child {
                            border-top: 1px solid #d2d2d2;
                        }
                        &:last-child {
                            border-bottom: 1px solid #d2d2d2;
                            @include max-screen(767px) {
                                border-bottom: none;
                            }
                        }
                        .detailProCart {
                            @include max-screen(767px) {
                                min-height: 245px;
                            }
                        }
                        .order_action {
                            @include max-screen(767px) {
                                bottom: 55px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.favorite {
        .page_wrap {
            .order_box {
                .order_box_item {
                    .eachProduct {
                        .deleteProductInCart {
                            top: -5px;
                            @include max-screen(767px) {
                                top: auto;
                            }
                        }
                        .titlePro {
                            @include max-screen(767px) {
                                margin: 0 0 15px;
                            }
                            p {
                                font-size: 12px;
                                padding-left: 23px;
                                margin-right: 5px;
                                position: relative;
                                &:before {
                                    display: block;
                                    content: "";
                                    width: 16px;
                                    height: 16px;
                                    background: url(../img/common/icon/icon_date.svg);
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    margin-top: -8px;
                                }
                            }
                        }
                        .datePro {
                            margin: -5px 0 25px;
                            @include max-screen(767px) {
                                margin-top: 0;
                            }
                            p,span {
                                display: inline;
                                position: relative;
                            }
                            p {
                                font-size: 12px;
                                padding-left: 23px;
                                margin-right: 5px;
                                &:before {
                                    display: block;
                                    content: "";
                                    width: 16px;
                                    height: 16px;
                                    background: url(../img/common/icon/icon_date.svg);
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    margin-top: -8px;
                                }
                            }
                            span {
                                font-size: 12px;
                                padding-left: 10px;
                                color: #787878;
                                &:before {
                                    display: block;
                                    content: "";
                                    width: 1px;
                                    height: 10px;
                                    background: #1E1E1E;
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    margin-top: -5px;
                                }
                            }
                        }
                        .detailProCart {
                            @include max-screen(767px) {
                                min-height: 300px;
                            }
                        }
                        .order_action {
                            @include max-screen(767px) {
                                bottom: 55px;
                            }
                            .wrap_btn {
                                .addCart {
                                    float: none;
                                    width: 270px;
                                    height: 45px;
                                    line-height: 45px;
                                    @include max-screen(767px) {
                                        width: 100%;
                                    }
                                }
                                .quantiryProduct {
                                    @include max-screen(767px) {
                                        top: -40px;
                                    }
                                }
                            }
                            .wrap_buy {
                                @include max-screen(767px) {
                                    margin: 10px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .paging {
            padding: 30px 0 50px;
        }
    }
    &.refusal {
        .bread {
            @include max-screen(767px) {
                margin-bottom: 18px;
            }
        }
        .sec_form {
            padding-bottom: 53px;
            @include max-screen(767px) {
                padding-bottom: 0;
            }
        }
        .page_title {
            @include max-screen(767px) {
                margin-bottom: 20px;
            }
        }
        .info {
            span {
                display: block;
                margin: 0 0 20px;
            }
            p {
                position: relative;
                padding: 15px 20px;
                text-align: justify;
                font-size: 13px;
                @include max-screen(767px) {
                    padding: 10px 20px;
                }
                &:before,
                &:after {
                    display: block;
                    content: "";
                    width: 12px;
                    height: 100%;
                    background: url(../img/login/bg_intro.png);
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include max-screen(767px) {
                        background: url(../img/login/bg_intro_sp.svg) no-repeat;
                    }

                }
                &:after {
                    @include transform_c(rotate(180deg));
                    right: 0;
                    left: auto;
                }
            }
        }
        table {
                width: 840px;
                border-collapse: collapse;
                border: 1px solid #DDDDDD;
                margin: 30px 0 60px;
                @include max-screen(767px) {
                    display: block;
                    border: none;
                    width: initial;
                    width: inherit;
                    margin: 20px 0 30px;
                }
                tbody {
                    @include max-screen(767px) {
                        display: block;
                        width: 100%;
                    }
                }
            tr  {
                @include max-screen(767px) {
                    display: block;
                    margin: 0 -25px;
                    border: none;
                }
                th, td {
                    padding: 20px 20px 10px;
                    @include max-screen(767px) {
                        display: block;
                        width: 100%;
                        border: none;
                    }
                }
                th {
                    width: 300px;
                    background: #FAE9E9;
                    text-align: left;
                    font-weight: normal;
                    border-left: 1px solid #DDDDDD;
                    border-right: 1px solid #DDDDDD;
                    @include max-screen(767px) {
                        display: block;
                        width: 100%;
                        border: none;
                        padding: 10px 25px;
                    }
                    span {
                        color: #D22522;
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
                td {
                    padding-left: 25px;
                    @include max-screen(767px) {
                        padding: 10px 25px;
                    }
                    label {
                        display: block;
                        @include max-screen(767px) {
                            height: 50px;
                            line-height: 50px;
                            text-align: center;
                            border: 1px solid #DDDDDD;
                            @include border-radius(5px);
                            margin: 0 0 10px;
                        }
                        input {
                            margin-right: 10px;
                            @include max-screen(767px) {
                                opacity: 0;
                            }
                        }
                        &.active {
                            @include max-screen(767px) {
                                background: #1E1E1E;
                                color: #FFF;
                                font-weight: 600;
                            }
                        }
                    }
                    textarea {
                        display: block;
                        width: 450px;
                        height: 180px;
                        line-height: normal;
                        margin: 15px 0 0;
                        padding: 15px;
                        vertical-align: top;
                        border-color: #DDDDDD;
                        @include max-screen(767px) {
                            height: 130px;
                            width: 100%;
                            padding: 15px;
                            vertical-align: top;
                        }
                    }
                }
            }
            @include min-screen(767px) {
                ::-webkit-input-placeholder { /* WebKit browsers */
                    color: transparent;
                }
                :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                   color: transparent;
                }
                ::-moz-placeholder { /* Mozilla Firefox 19+ */
                   color: transparent;
                }
                :-ms-input-placeholder { /* Internet Explorer 10+ */
                   color: transparent;
                }

                textarea::-webkit-input-placeholder { /* WebKit browsers */
                    color: transparent;
                }
                textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                   color: transparent;
                }
                textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
                   color: transparent;
                }
                textarea:-ms-input-placeholder { /* Internet Explorer 10+ */
                   color: transparent;
                }

            }
        }


        .page_btn {
            margin: 0 auto 60px;
            @include max-screen(767px) {
                margin: 0 auto 30px;
            }
        }
        .regular_policy {
            margin: 0 0 30px;
        }
        &.refusal_finish {
            .header02 {
                .logo {
                    @include max-screen(767px) {
                        padding-top: 90px;
                    }
                    a:hover {
                        opacity: 0.5;
                    }
                }
            }

            .finish_box {
                padding: 85px 0 0;
                text-align: center;
                height: calc(100vh - 235px);
                @include max-screen(767px) {
                    padding: 60px 0 0;
                    height: calc(100vh - 184px);
                }
                img {
                    width: 80px;
                    @include max-screen(767px) {
                        width: 70px;
                    }
                }
                h3 {
                    font-size: 26px;
                    color: $colortxt1;
                    font-weight: normal;
                    margin: 30px 0 30px;
                    @include max-screen(767px) {
                        font-size: 19px;
                        margin: 20px 0 20px;
                    }
                }
            }
        }
    }

}















