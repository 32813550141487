#product {
	.block {
		@include max-screen(767px) {padding-bottom: 30px;
			.container {padding: 0 15px;}
		}
		.barTitle {background: #f6f6f6; margin-bottom: 36px; margin-left: -10px; margin-right: -10px; padding: 0 10px;
			@include max-screen(767px) {margin-bottom: 0;}
			h2 {font-size: 20px; letter-spacing: 4px; line-height: 54px;
				@include max-screen(767px) {line-height: 43px; font-size: 14px; padding: 0 15px; letter-spacing: 1px;}
			}
		}
		.bigTitle {margin-bottom: 45px;
			@include max-screen(767px) {margin-bottom: 25px;}
			h2 {font-size: 26px; text-align: center; line-height: 1; letter-spacing: 4px;
				@include max-screen(767px) {font-size: 20px; letter-spacing: 1px;}
			}
		}
	}
	.blockListProduct {margin-bottom: 70px;
		@include max-screen(767px) {margin: 0; padding: 0;}
		.container {max-width: 1000px; margin: 0 auto;
			@include max-screen(767px) {padding: 0;}
		}
	}
	.listProduct {margin-left: -6px; margin-right: -6px;
		@include max-screen(767px) {margin: 0 -10px 0;}
		.eachProduct {float: left; width: 20%; padding: 0 6px;
			@include min-screen(768px) {
				&:nth-child(5n+1) {clear: both;}
			}
			@include max-screen(767px) {float: none; width: auto; padding: 10px 35px 10px 15px; border-bottom: 1px solid #bbbbbb; position: relative;
				&:after {content: ""; display: block; width: 8px; height: 8px; border-top: 2px solid #8e8e8e; border-right: 2px solid #8e8e8e; @include transform_c(rotate(45deg)); position: absolute; top: 50%; right: 15px; margin-top: -4px;}
			}
			.innerE {position: relative; padding: 20px 0; border: 1px solid transparent; @include transition_c(0.3s ease all);
				@include max-screen(767px) {padding: 0;}
				.clearfix {
					.img {
						img {display: block; max-height: 190px; margin: 0 auto 20px;
							@include max-screen(767px) {margin-bottom: 0;}
						}
						@include max-screen(767px) {float: left; width: 100px; margin-left: -15px; padding: 0 10px;}
					}
					.introProduct {text-align: center;
						@include max-screen(767px) {text-align: left; overflow: hidden;}
						.tagProduct {font-size: 10px; color: #fff; display: inline-block; padding: 0 10px; border-radius: 10px;
							@include max-screen(767px) {font-size: 10px;
								&:empty {display: none;}
							}
							&.tagPurple {background: #7189c4;}
							&.tagOrange {background: #e88400;}
						}
						.categoryProduct {font-size: 12px; color: #00bbcc;
							@include max-screen(767px) {font-size: 11px;
								span {display: inline-block; margin-right: 10px; white-space: nowrap;}
								.spTag {display: inline-block; font-style: normal; color: #fff; border-radius: 10px; font-size: 10px; padding: 0 10px; line-height: 16px; white-space: nowrap;
									&.tagPurple {background: #7189c4;}
									&.tagOrange {background: #e88400;}
								}
							}
						}
						.titleProduct {font-size: 15px; padding: 0 15px;
							@include max-screen(767px) {font-size: 14px; padding: 12px 0;
								h4 {font-weight: bold; line-height: 1; overflow: hidden; height: 14px; text-overflow: ellipsis; white-space: nowrap;}
							}
						}
						.priceProduct {font-size: 15px;
							@include max-screen(767px) {font-size: 11px;
								p {display: inline-block; vertical-align: middle; white-space: nowrap;}
							}
							.smallPrice {font-size: 12px;
								@include max-screen(767px) {font-size: 11px;}
							}
						}
					}
				}
				&.canHover:hover {border: 1px solid #00a3c2;}
			}
		}
	}
	.anchorList {
		ul {font-size: 0; letter-spacing: 0; margin-left: -1px; margin-right: -1px;
			li {display: inline-block; font-size: 14px; width: 20%; line-height: 1; letter-spacing: 1px; padding: 0 1px; margin-bottom: 3px; vertical-align: top;
				a {display: block; text-align: center; background: #f2f2f3; border-radius: 100px; position: relative; padding: 9px 0 9px 0;
					&:after {content: ""; display: block; position: absolute; top: 50%; right: 15px; width: 5px; height: 5px; border-right: 1px solid #1e1e1e; border-bottom: 1px solid #1e1e1e; @include transform_c(rotate(45deg)); margin-top: -4px;}
				}
			}
		}
		&.anchorListPupose ul li:nth-child(7) {width: 40%;}
	}
	&.product_list_page {
		.blockListProduct {margin-bottom: 100px;}
		@include max-screen(767px) {
			.blockListProduct {margin-bottom: 0;
				&:first-child {margin-bottom: 50px;}
				.blockList:last-child .titleCategory {border-bottom: 1px solid #bbb;}
			}
		}
		.blockList {
			.titleCategory {color: #fff; background: #00bbcc; text-align: center; font-weight: normal; margin: 45px 0 20px; @include transition_c(0.3s ease all);
				@include max-screen(767px) {color: #1e1e1e; background: #f6f6f6; text-align: left; margin: 0 -10px; padding: 0 25px; border-top: 1px solid #bbbbbb; position: relative;
					&:before, &:after {content: ""; display: block; width: 3px; height: 13px; background: #8e8e8e; position: absolute; top: 50%; right: 20px; margin-top: -10px; @include transition_c(0.3s ease all);}
					&:after {@include transform_c(rotate(90deg));}
				}
				h3 {line-height: 36px; font-size: 16px;
					@include max-screen(767px) {line-height: 1.5; font-size: 14px; padding: 10px 10px 10px 0px;}
				}
			}
			.listProduct {
				@include max-screen(767px) {display: none;}
			}
			&.open {
				@include max-screen(767px) {
					.titleCategory {color: #fff; background: #1e1e1e;
						&:before, &:after {background: #fff;}
						&:before {@include transform_c(rotate(90deg));}
						&:after {@include transform_c(rotate(0)); opacity: 0;}
					}
					// .listProduct {display: block;}
				}
			}
			@include max-screen(767px) {
				&:not(:last-child) .eachProduct:last-child {border-bottom: 0;}
			}
		}
	}
	&.product_detail_page {
		// background: url(../img/product/detail.jpg) center -3px no-repeat;
		// > * {opacity: 0.5;}
		.bread {padding-top: 15px;}
		.block .barTitle {background: #a1b2ba;
			h2 {text-align: center; color: #fff; line-height: 80px;}
		}
		.blockListProduct {margin-bottom: 60px;
			@include max-screen(767px) {margin: 0;}
		}
		.buttonArea {padding: 49px 0; background: rgba(196,216,220,0.3); margin: 0 -10px 10px;
			@include max-screen(767px) {margin: 0 -10px 5px; padding: 30px 25px;}
			.wrap {max-width: 675px; margin: 0 auto;}
			.txt {text-align: center; font-size: 16px; margin-bottom: 30px; line-height: 1;
				@include max-screen(767px) {font-size: 14px;}
				@include max-screen(374px) {margin-left: -25px; margin-right: -25px;}
			}
			.rowBtn {font-size: 0; letter-spacing: 0;
				&.rowBtnMB {margin-bottom: 15px;
					@include max-screen(767px) {margin-bottom: 10px;}
				}
				.product_quantity {display: inline-block; vertical-align: middle; width: calc(100% - 500px);
					label {font-size: 14px; display: inline; vertical-align: middle; letter-spacing: 1px;}
					.selectDiv {display: inline-block; vertical-align: middle; margin-left: 10px; position: relative;
						select {font-size: 15px; letter-spacing: 1px; width: 112px; height: 38px; -webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; appearance: none; border: 1px solid #dddddd; padding: 0 10px; background: #fff; font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;}
						&:after {width: 5px; height: 5px; content: ""; display: block; border-bottom: 1px solid #8e8e8e; border-right: 1px solid #8e8e8e; @include transform_c(rotate(45deg)); position: absolute; top: 50%; right: 10px; margin-top: -3px;}
					}
					@include max-screen(767px) {width: 95px; margin-right: 10px;
						label {display: none;}
						.selectDiv {margin: 0; width: 100%;
							select {height: 37px; width: 100%;}
						}
					}
				}
				.btn {display: inline-block; vertical-align: middle; text-align: center;
					span {display: inline-block;}
					&.btnAddtocart {width: 500px; font-size: 18px; letter-spacing: 1px; background: #00bbcc; color: #fff; height: 62px; line-height: 62px;
						span {background: url(../img/product/icoCart.svg) left center no-repeat; padding-left: 30px;}
					}
					&.btnAddtofav {font-size: 15px; letter-spacing: 1px; border: 1px solid #1e1e1e; height: 42px; line-height: 40px; width: 330px; margin-right: 15px;
						span {padding-left: 20px; background: url(../img/product/icoFav.svg) left center no-repeat;}
					}
					&.btnAddtosubcribe {font-size: 15px; letter-spacing: 1px; background: #1e1e1e; color: #fff; height: 42px; line-height: 42px; border: 0; width: 330px;}
					@include max-screen(767px) {
						&.btnAddtocart {line-height: 45px; height: 45px; width: calc(100% - 105px); font-size: 15px;}
						&.btnAddtofav {width: calc(49% - 3px); margin-right: 10px; font-size: 14px;}
						&.btnAddtosubcribe {width: calc(49% - 3px); float: right; font-size: 14px;}
					}
					@include max-screen(374px) {
						&.btnAddtocart {font-size: 14px;}
						&.btnAddtofav {font-size: 12px;}
						&.btnAddtosubcribe {font-size: 12px;}
					}
				}
			}
			// .btn {font-size: 15px; color: #1e1e1e; background: #fff; border: 1px solid #1e1e1e; line-height: 40px; text-align: center; display: block; cursor: pointer;
			// 	@include max-screen(767px) {line-height: 36px; font-size: 14px;}
			// 	span {display: inline-block; padding-left: 20px; background: url(../img/product/icoFav.svg) left center no-repeat;
			// 		@include max-screen(767px) {display: inline;}
			// 	}
			// 	&.btnColor {font-size: 18px; color: #fff; line-height: 62px; background: #00bbcc; border-color: #00bbcc; margin-bottom: 10px;
			// 		@include max-screen(767px) {line-height: 43px; font-size: 15px; margin-bottom: 5px;}
			// 		span {background-image: url(../img/product/icoCart.svg); padding-left: 30px;}
			// 	}
			// }
		}
		.blockReview {border-top: 1px solid #d2d2d2; margin-left: -10px; margin-right: -10px; padding: 60px 0 50px;
			@include max-screen(767px) {margin: 0; padding-bottom: 30px; border-top: 0;}
			.titleBlockReview {text-align: center; font-size: 26px; line-height: 1; margin-bottom: 25px;
				@include max-screen(767px) {font-size: 20px;}
			}
			.container {max-width: 1000px; margin: 0 auto;}
			.listReview {margin-bottom: 40px; perspective: 2000px; perspective-origin: center bottom;
				@include max-screen(767px) {margin-bottom: 30px;}
				.eachReview {margin-bottom: 10px; transition: 1s ease all; position: relative; opacity: 1;
					&.doneshow {transform: rotateX(90deg); transform-origin: center top; overflow: hidden; margin-bottom: 0; opacity: 0;}
					.inner {border: 2px solid #d65fa5; padding: 35px 30px 20px; background: #fff;
						@include max-screen(767px) {padding: 15px;}
						.titleReview {font-size: 20px; font-weight: bold; margin-bottom: 20px; line-height: 1;
							@include max-screen(767px) {display: none;}
						}
						.starts {color: #d65fa5; line-height: 1; margin-bottom: 10px;
							ul {
								li {display: inline-block;}
							}
						}
						.txt01 {color: #d65fa5; font-size: 12px; line-height: 1;}
						.txt02 {line-height: 2; margin-top: 20px; line-height: 2;
							@include max-screen(767px) {font-size: 12px; line-height: 1.5;}
						}
					}
				}
			}
			.textCount {text-align: center; margin-bottom: 25px;
				a {color: #d65fa5; text-decoration: underline;}
				@include max-screen(767px) {margin-bottom: 15px;}
			}
			.btnReview {font-weight: bold; font-size: 15px; display: block; margin: 0 auto; width: 180px; line-height: 46px; background: #d65fa5; color: #fff; border: 1px solid #d65fa5;
				span {display: block; text-align: center; background: url(../img/product/icoPlus.svg) right 20px center no-repeat; padding-right: 20px;}
				&.open {background: #fff; border-color: #1e1e1e; color: #1e1e1e;
					span {background-image: url(../img/product/icoMinus.svg);}
				}
				@include max-screen(767px) {line-height: 45px; font-size: 15px; width: 160px;
					span {}
				}
			}
			.writeReview {padding: 80px 0 40px;
				@include max-screen(767px) {padding-bottom: 0;
					&.writeReviewStep02 {padding-left: 10px; padding-right: 10px;
						.tableForm {
							tbody, tr {}
							th, td {background: #fff; padding-left: 15px; padding-right: 15px;}
							td {border-bottom: 1px solid #8e8e8e; padding-top: 0; line-height: 1.4;}
						}
						.submitReview {width: 100%; margin: 0;}
					}
				}
			}
			.titleWriteReview {font-size: 26px; color: #d65fa5; text-align: center; line-height: 1; margin-bottom: 30px;
				@include max-screen(767px) {font-size: 20px; margin-bottom: 20px;}
			}
			.tableForm {width: 800px; margin: 0 auto;
				th, td {height: 60px; padding: 13px 24px; border: 1px solid #dddddd; text-align: left;}
				th {background: #ebebed; font-weight: normal; width: 260px;}
				td {}
				input[type='text'] {display: block; width: 100%; height: 40px; border: 1px solid #dddddd; padding: 0 10px;
					&#shipping_zipcode {display: inline-block; width: 136px; margin-bottom: 10px;}
				}
				textarea {width: 100%; height: 183px; border: 1px solid #dddddd; background: #fff; padding: 10px;
					@include max-screen(767px) {height: 140px;}
				}
				.selectDiv {display: inline-block; vertical-align: middle; position: relative;
					@include max-screen(767px) {display: block; width: 100%;}
					select {-webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; appearance: none; border: 1px solid #dddddd; height: 38px; padding: 0 10px; font-size: 15px; width: 220px; background: #fff; font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
						@include max-screen(767px) {font-size: 13px; width: 100%; height: 40px;}
					}
					&:after {content: ""; display: block; border: 10px solid #8e8e8e; position: absolute; top: 50%; right: 10px; margin-top: -4px; border-color: #8e8e8e transparent transparent transparent; border-width: 8px 5px;}
				}
				.checkRadio {
					@include min-screen(768px) {
						label {display: inline-block; margin-right: 15px;}
						input:checked,
						input:not(:checked) {
						    position: absolute;
						    left: -9999px;
						}
						input:checked + label,
						input:not(:checked) + label
						{
						    position: relative;
						    padding-left: 28px;
						    cursor: pointer;
						    line-height: 20px;
						    display: inline-block;
						    color: #1e1e1e;
						}
						input:checked + label:before,
						input:not(:checked) + label:before {
						    content: '';
						    position: absolute;
						    left: 0;
						    top: 0;
						    width: 18px;
						    height: 18px;
						    border: 1px solid #1e1e1e;
						    border-radius: 100%;
						    background: #fff;
						}
						input:checked + label:after,
						input:not(:checked) + label:after {
						    content: '';
						    width: 12px;
						    height: 12px;
						    background: #F87DA9;
						    position: absolute;
						    top: 3px;
						    left: 3px;
						    border-radius: 100%;
						    -webkit-transition: all 0.2s ease;
						    transition: all 0.2s ease;
						}
						input:not(:checked) + label:after {
						    opacity: 0;
						    -webkit-transform: scale(0);
						    transform: scale(0);
						}
						input:checked + label:after {
						    opacity: 1;
						    -webkit-transform: scale(1);
						    transform: scale(1);
						}
					}
					@include max-screen(767px) {
						input {opacity: 0; position: absolute;}
					}
					@include max-screen(767px) {
						label {
							display: block; line-height: 38px; border: 1px solid #dddddd; width: 100%; text-align: center; position: relative; margin-bottom: 10px;
							&.checked {border: 1px solid #eaafd2; background: #fbeff6;}
							&:last-child {margin-bottom: 0;}
						}
					}
					input {}
				}
				@include max-screen(767px) {display: block; width: auto; margin-left: -10px; margin-right: -10px; margin-bottom: 10px;
					tbody, tr {display: block; width: 100%;}
					th, td {display: block; width: 100%; height: auto; padding: 0 25px; border: 0;}
					th {line-height: 32px;}
					td {padding-top: 10px; padding-bottom: 10px;}
					.noticeAddress {display: block; font-size: 12px; padding-left: 20px;}
				}
			}
			.backtoStep01 {text-align: right; margin: 10px auto 60px; max-width: 800px;
				@include max-screen(767px) {margin-bottom: 40px;}
				a {display: inline-block; position: relative;
					&:before {content: ""; border: 5px solid #d65fa5; border-color: transparent transparent transparent #d65fa5; display: inline-block; vertical-align: middle; border-width: 3px 5px;}
				}
			}
			.reviewStep03 {border: 1px solid #00bbcc; max-width: 690px; margin: 0 auto; padding: 50px 0; background: #fff;
				@include max-screen(767px) {padding: 25px 0; border: 0; background: #ebebed; margin-left: -10px; margin-right: -10px;}
				.txt01 {font-size: 26px; color: #d65fa5; text-align: center; margin-bottom: 25px; line-height: 1;
					@include max-screen(767px) {margin-bottom: 10px; line-height: 1.5; font-size: 16px;}
				}
				.txt02 {text-align: center; line-height: 1;
					@include max-screen(767px) {line-height: 1.5;}
				}
			}
			.submitReview {display: block; margin: 25px auto 0; width: 500px; background: #d65fa5; line-height: 62px; text-align: center; font-size: 18px; color: #fff; border: 0; position: relative; letter-spacing: 2px;
				&:after {content: ""; display: block; width: 6px; height: 6px; border-top: 2px solid #fff; border-right: 2px solid #fff; @include transform_c(rotate(45deg)); position: absolute; top: 50%; right: 10px; margin-top: -3px;}
				&:hover {opacity: 0.8;}
				@include max-screen(767px) {width: calc(100% - 30px); line-height: 45px; font-size: 15px; margin: 0 15px;}
			}
		}
		.blockDetailProduct {
			@include max-screen(767px) {margin-left: -10px; margin-right: -10px; border-top: 1px solid #1e1e1e;}
			.container {max-width: 1000px; margin: 0 auto;}
			.section {border-top: 3px solid #a1b2ba; margin-bottom: 47px;
				@include max-screen(767px) {border-top: 0; margin-bottom: 0;}
				.titleSection {font-size: 20px; border-bottom: 1px solid #a1b2ba; line-height: 60px; text-align: center;
					@include max-screen(767px) {line-height: 43px; text-align: left; font-size: 14px; padding: 0 25px; background: #f6f6f6; border-color: #bbbbbb; position: relative;
						&:before, &:after {content: ""; display: block; width: 3px; height: 13px; background: #8e8e8e; position: absolute; top: 50%; right: 20px; margin-top: -8px; @include transition_c(0.3s ease all);}
						&:after {@include transform_c(rotate(90deg));}
						&.open {
							&:before {@include transform_c(rotate(90deg));}
							&:after {@include transform_c(rotate(0)); opacity: 0;}
						}
					}
				}
				.contentSection {line-height: 2; padding: 25px 0 0;
					@include max-screen(767px) {padding: 20px 25px; display: none; line-height: 1.5;}
				}
				.img01 {margin-bottom: 30px;
					@include max-screen(767px) {margin-bottom: 20px;}
				}
				.clearfix {
					.left {float: left; max-width: calc(100% - 460px); padding-right: 50px; padding-left: 30px;
						@include max-screen(767px) {float: none; width: auto; max-width: none; padding-right: 0;}
						ul {
							li {text-indent: -30px; margin-bottom: 10px;
								span {color: #ff72bd; font-family: 'Roboto', Arial; font-size: 20px; display: inline-block; text-indent: 0; width: 30px; vertical-align: middle;}
								@include max-screen(767px) {line-height: 1.5;}
							}
						}
					}
					.right {float: right; max-width: 460px; padding: 12px 0;
						@include max-screen(767px) {float: none; max-width: none; padding: 0;}
					}
				}
			}
		}
		.blockIntroProduct {padding-top: 8px;
			@include max-screen(767px) {padding-top: 0;}
			.container {max-width: 1000px; margin: 0 auto;
			}
			.bigImage {float: left; width: 460px; margin-right: 40px;
				img {display: block;}
				@include max-screen(767px) {float: none; width: auto; margin: 0 0 20px;}
			}
			.right {overflow: hidden; position: relative;
				.product_name {font-size: 26px; margin-bottom: 14px; margin-top: -10px;
					@include max-screen(767px) {font-size: 20px; text-align: center; margin-top: 0;}
				}
				.txt01 {
					@include max-screen(767px) {padding: 0 15px 20px; border-bottom: 1px solid #bbbbbb;}
				}
				.other {position: relative; padding-bottom: 140px;
					@include max-screen(767px) {padding: 15px 15px;}
					.product_price {margin: 13px 0 10px;
						@include max-screen(767px) {margin: 0 80px 10px 0; line-height: 1.5;}
						span {
							@include max-screen(767px) {font-size: 13px;}
						}
						span:nth-child(1) {font-size: 30px;
							@include max-screen(767px) {font-size: 23px;}
						}
						span:nth-child(2) {font-size: 18px;
							@include max-screen(767px) {font-size: 13px;}
						}
						span:nth-child(3) {}
					}
					.product_code_weight {font-size: 12px;
						span {line-height: 1;}
						span:nth-child(1) {color: #787878;}
						span:nth-child(2) {border-left: 1px solid #1e1e1e; display: inline-block; padding-left: 10px; margin-left: 5px;
							@include max-screen(767px) {display: none;}
						}
					}
					.product_weight {font-size: 12px;}
					.product_quantity {
						label {
							@include max-screen(767px) {display: none;}
						}
						.selectDiv {display: inline-block; vertical-align: middle; position: relative;
							select {-webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; appearance: none; border: 1px solid #8e8e8e; height: 38px; padding: 0 10px; font-size: 15px; width: 112px; background: #fff; font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; margin-left: 15px;
								@include max-screen(767px) {font-size: 13px; width: 95px; height: 37px; margin-left: 0;}
							}
							&:after {width: 5px; height: 5px; content: ""; display: block; border-bottom: 1px solid #8e8e8e; border-right: 1px solid #8e8e8e; @include transform_c(rotate(45deg)); position: absolute; top: 50%; right: 10px; margin-top: -3px;}
							@include max-screen(767px) {position: absolute; top: 20px; right: 15px;
								select {}
							}
						}
					}
					.product_buttons {position: absolute; bottom: 0; left: 0; width: 100%; font-size: 0;
						.btn {display: inline-block; line-height: 40px; text-align: center; border: 1px solid #1e1e1e; font-size: 15px; width: 330px;
							span {display: inline-block; padding-left: 20px; background: url(../img/product/icoFav.svg) left center no-repeat;}
							&.btnAddtocart {font-size: 18px; color: #fff; line-height: 62px; background: #00bbcc; border-color: #00bbcc; margin-bottom: 10px; display: block; width: 100%;
								span {background-image: url(../img/product/icoCart.svg); padding-left: 30px;}
							}
							&.btnSubcribe {width: calc(100% - 341px); background: #1e1e1e; color: #fff; margin-left: 10px;
								span {background: transparent; padding: 0;}
							}
						}
						@include max-screen(767px) {position: relative; margin: 20px 0;
							.btn {width: calc((100% - 10px) / 2); line-height: 36px;
								span {display: inline;}
								&.btnAddtocart {font-size: 15px; line-height: 43px; margin-bottom: 10px;
									span {}
								}
								&.btnSubcribe {margin-left: 10px; width: calc((100% - 10px) / 2 - 1px);
									span {}
								}
							}
						}
					}
					.product_brief {font-size: 12px; color: #4b4b4b; letter-spacing: 0; margin: 10px 0 30px;
						@include max-screen(767px) {margin-bottom: 0;}
					}
				}
			}
			.recommend {margin: 51px 0;
				@include max-screen(767px) {margin: 10px -10px 30px; background: rgba(196,216,220,0.3); padding: 25px;}
				.titleRecommend {color: #616161; font-size: 16px; margin-bottom: 14px;
					@include max-screen(767px) {text-align: center; font-size: 15px; margin-bottom: 15px;}
					span {display: inline-block; border-bottom: 1px solid #616161;
						@include max-screen(767px) {display: inline;}
					}
				}
				ul {
					li {margin-bottom: 5px; background: url(../img/product/icoCheck.svg) left center no-repeat; padding-left: 20px;}
				}
			}
		}
	}
	.footer {
		@include max-screen(767px) {margin: 0;}
	}
}