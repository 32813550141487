.privacySec {
	padding-bottom: 55px;
	.mHead {
		// background: rgba(197, 217, 221, 0.3);
		background: #f1f5f7;
		padding: 12px 10px 12px;
		margin-bottom: 28px;
		margin-top: 49px;
		&:first-child {
			margin-top: 0;
		}
		h3 {
			max-width: 840px;
			padding: 0 20px 0 28px;
			font-size: 20px;
			color: #1e1e1e;
		}
	}
	.secInner {
		max-width: 840px;
		padding: 0 28px;
		p {
			font-size: 14px;
			letter-spacing: 1px;
			line-height: 2;
		}
	}
	@include max-screen(767px) {
		padding-bottom: 8px;
		.mHead {
			padding: 12px 0;
			margin-bottom: 20px;
			margin-top: 35px;
			h3 {
				font-size: 14px;
				font-weight: bold;
				padding: 0 25px;
			}
		}
		.secInner {
			padding: 0 15px;
		}
	}
}