@charset "utf-8";

.login {
    /*COMMON LOGIN*/
    .guideSec,
    .social,
    .blank_link {
        display: none;
    }
    .header02 {
        min-height: 106px;
        @include max-screen(767px) {
            min-height: 62px;
        }
    }
    #wrap {
        margin: 106px 0 0;
        @include max-screen(767px) {
            margin: 62px 0 0;
        }
    }
    .inner {
        @include max-screen(767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    /* Custom checkbox*/
    .wrap_checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @include max-screen(767px) {
            padding-left: 20px;
        }
    }

    /* Hide the browser's default checkbox */
    .wrap_checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: #FFF;
        border: 2px solid #DDDDDD;
        @include max-screen(767px) {
            height: 15px;
            width: 15px;
            top: 5px;
        }
    }

    /* On mouse-over, add a grey background color */
    .wrap_checkbox:hover input ~ .checkmark {
        background-color: #FFF;
    }

    /* When the checkbox is checked, add a blue background */
    .wrap_checkbox input:checked ~ .checkmark {
        background-color: #FFF;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .wrap_checkbox input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .wrap_checkbox .checkmark:after {
        left: 7px;
        top: 3px;
        width: 6px;
        height: 14px;
        border: solid #FF0000;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        @include max-screen(767px) {
            left: 5px;
            top: 1px;
            width: 4px;
            height: 8px;
            border: solid #FF0000;
            border-width: 0 2px 3px 0;
        }
    }
    /*Login top*/
    &.login_top {
        .header02 {
            position: static;
        }
        .footer {
            @include max-screen(767px) {
                margin-top: 15px;
            }
        }
        #wrap {
            margin: 0;
        }
        .header02 .logo {
            float: none;
            width: 100%;
            text-align: center;
            padding: 60px 0 0;
            @include max-screen(767px) {
                padding: 90px 0 0;
            }
            img {
                width: 270px;
                height: auto;
                margin: 0;

            }
            span {
                display: block;
                padding: 7px 0 0;
            }
        }

        .sec01 {
            padding: 90px 0 0;
            @include max-screen(767px) {
                padding: 30px 0 0;
            }
            .inner {
                max-width: 1000px;
                width: 100%;
                margin: 0 auto;
                border-bottom: 1px solid #D2D2D2;
                padding: 0 0 50px;
                @include max-screen(767px) {
                    padding: 0 15px 25px;
                }
            }
            table {
                max-width: 500px;
                width: 100%;
                margin: 0 auto 50px;
                @include max-screen(767px) {
                    display: block;
                    margin: 0 auto 25px;
                }
                tbody {
                    @include max-screen(767px) {
                        display: block;
                    }
                }
                tr {
                    @include max-screen(767px) {
                        display: block;
                        margin: 0 0 20px;
                    }
                    &:nth-child(2) {
                        margin: 0 0 5px;
                    }
                    th,
                    td {
                        padding: 7px 10px;
                        vertical-align: top;
                        @include max-screen(767px) {
                            display: block;
                            padding: 0;
                            width: 100% !important;
                        }
                    }
                    th {
                        width: 100px;
                        padding-top: 20px;
                        text-align: left;
                        @include max-screen(767px) {
                            padding: 0 0 6px;
                        }
                    }
                    td {
                        span {
                            display: block;
                            padding: 5px 0 0;
                            font-size: 12px;
                            @include max-screen(767px) {
                                font-size: 11px;
                            }
                        }
                        input {
                            width: 100%;
                            height: 40px;
                            border: 1px solid #DDDDDD;
                            background: #FFF;
                            padding: 0 10px;
                        }
                    }
                }
            }
            button.btn-black {
                background: #1E1E1E;
                @include max-screen(767px) {
                    width: 200px;
                    height: 45px;
                    line-height: 45px;
                }
            }
            .forget {
                text-align: center;
                position: relative;
                margin: 35px 0 0;
                a {
                    position: relative;
                    &:after {
                        display: block;
                        content: "";
                        width: 10px;
                        height: 10px;
                        background: url(../img/common/icon/arrow_black.svg);
                        position: absolute;
                        top: 50%;
                        right: -20px;
                        margin-top: -5px;
                    }
                }
            }
        }
        .sec02 {
            padding: 50px 0 22px;
            @include max-screen(767px) {
                padding: 30px 0 0;
            }
            .inner {
                max-width: 700px;
                width: 100%;
                margin: 0 auto;
                padding: 0 0 50px;
                @include max-screen(767px) {
                    padding: 30px 15px 0;
                }
            }
            h3 {
                font-size: 26px;
                color: $colortxt1;
                text-align: center;
                margin: 0 0 15px;
                @include max-screen(767px) {
                    font-size: 20px;
                }
            }
            p {
                text-align: center;
                margin: 0 0 54px;
                @include max-screen(767px) {
                    margin: 0 0 20px;
                }
            }
            h4 {
                background: #F4F4F4;
                padding: 10px 25px;
                margin: 0 0 20px;
                font-size: 20px;
                @include max-screen(767px) {
                    padding: 5px 10px;
                    margin: 0 0 10px;
                    font-size: 14px;
                }
            }
            span {
                font-size: 12px;
                margin: 8px 0 0;
                display: block;
            }
            table {
                max-width: 500px;
                width: 100%;
                margin: 0 auto 60px;
                @include max-screen(767px) {
                    margin: 0 auto 30px;
                }
                tr {
                    td {
                        &:first-child {
                            width: 65%;
                            position: relative;
                            &:after {
                                display: block;
                                content: "";
                                width: 10px;
                                height: 2px;
                                background: #000;
                                position: absolute;
                                right: -15px;
                                top: 20px;
                            }
                        }
                        &:last-child {
                            padding-left: 20px;
                            width: 35%;
                        }
                    }
                    input {
                        width: 100%;
                        height: 40px;
                        border: 1px solid #DDDDDD;
                        background: #FFF;
                        padding: 0 10px;
                    }
                }
            }
            button.btn-blue {
                background: #00BBCC;
                @include max-screen(767px) {
                    width: 200px;
                    height: 45px;
                    line-height: 45px;
                }
            }
            .note {
                margin: 75px 0 0;
                line-height: 28px;
                @include max-screen(767px) {
                    margin: 50px 0 0;
                }
            }
        }
    }
    /*Login agreement*/
    &.agreement {
        .bread {
            margin-bottom: 60px;
            @include max-screen(767px) {
                margin-bottom: 30px;
            }
        }
        .sec {
            padding: 0 0 100px;
            @include max-screen(767px) {
                padding: 0 0 50px;
            }
            h3 {
                text-align: center;
                margin: 0 0 50px;
                font-size: 14px;
                @include max-screen(767px) {
                    text-align: left;
                    margin: 0 0 20px;
                }
            }
            .box {
                border: 1px solid #D2D2D2;
                padding: 60px 20px 70px 100px;
                margin: 0 0 60px;
                height: 1000px;
                text-align: justify;
                @include max-screen(767px) {
                    height: 400px;
                    overflow: hidden;
                    padding: 20px 5px 20px 15px;
                    margin: 0 0 30px;
                }

                .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
                    width: 10px;
                }
                .mCSB_scrollTools .mCSB_draggerRail {
                    width: 10px;
                }
                .mCSB_container {
                    margin-right: 80px;
                    @include max-screen(767px) {
                        margin-right: 30px;
                    }
                }

                h4 {
                    font-size: 26px;
                    color: $colortxt1;
                    text-align: center;
                    margin: 0 0 40px;
                    @include max-screen(767px) {
                        font-size: 20px;
                        margin: 0 0 15px;
                    }
                }
                div {
                    margin-bottom: 40px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .note {
                text-align: center;
                margin: 0 0 50px;
                @include max-screen(767px) {
                    text-align: left;
                    margin: 0 0 20px;
                }
            }
            .page_btn {
                font-weight: normal;
                @include max-screen(767px) {
                    height: 45px;
                    line-height: 45px;
                }
            }
        }
    }
    /*Login login1*/
    &.login1 {
        .header02 {
            .header02__phone_sp {
                @include max-screen(767px) {
                    display: none;
                }
            }
            .logo {
                @include max-screen(767px) {
                    text-align: center;
                    padding: 12px 0 0;
                }
                img {
                    @include max-screen(767px) {
                        width: 100px;
                        margin: 0;
                    }
                }
                span {
                    @include max-screen(767px) {
                        display: block;
                        font-size: 9px;
                        padding-top: 5px;
                    }
                }
            }
        }

        .bHead {
            margin-bottom: 0;
        }
        .bread {
            background: rgba(194, 213, 218, 0.3);
            margin-bottom: 0;
            padding-bottom: 40px;
            padding-top: 15px;
            @include max-screen(767px) {
                padding-bottom: 30px;
            }
        }
        .secInner {
            max-width: 800px;
            @include max-screen(767px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .sec01 {
            background: rgba(194, 213, 218, 0.3);
            margin: 0 -10px;
            padding: 25px 0 40px;
            @include max-screen(767px) {
                padding: 0 10px 25px;
            }
            ul {
                li {
                    display: table;
                    width: 100%;
                    background: #00BBCC;
                    margin: 0 0 2px;
                    padding: 2px;
                    @include max-screen(767px) {
                        padding: 1px;
                        margin: 0 0 1px;
                    }
                    div {
                        display: table-cell;
                        input {
                            width: 100%;
                            background: #FFF;
                            border: none;
                            height: 40px;
                            padding: 0 20px;
                            font-size: 16px;
                            @include max-screen(767px) {
                                height: 30px;
                                line-height: 30px;
                            }
                        }
                    }
                    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                      color: #1E1E1E;
                    }
                    ::-moz-placeholder { /* Firefox 19+ */
                      color: #1E1E1E;
                    }
                    :-ms-input-placeholder { /* IE 10+ */
                      color: #1E1E1E;
                    }
                    :-moz-placeholder { /* Firefox 18- */
                      color: #1E1E1E;
                    }
                    .th {
                        width: 175px;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 600;
                        color: #FFF;
                        @include max-screen(767px) {
                            width: 120px;
                            font-size: 14px;
                        }
                    }
                    .td {

                    }
                }
            }
            .note {
                padding: 35px 0 0;
                margin: 0 0 35px;
                letter-spacing: 1.5px;
                @include max-screen(767px) {
                    padding: 20px 0 0;
                    margin: 0 0 20px;
                    font-size: 12px;
                }
                p {

                }
            }
            .btn_back {
                width: 240px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                border: 1px solid #1E1E1E;
                margin: 0 auto;
                font-size: 15px;
                display: block;
                position: relative;
                @include max-screen(767px) {
                    width: 200px;
                }
                &:before {
                    display: block;
                    content: "";
                    width: 16px;
                    height: 16px;
                    background: url(../img/common/icon/arrow_left_black.svg);
                    position: absolute;
                    top: 50%;
                    left: 2px;
                    margin-top: -9px;
                }
            }
        }
        .sec_form {
            padding: 55px 0 73px;
            @include max-screen(767px) {
                padding: 30px 0 20px;
            }
            .info {
                margin: 0 0 42px;
                @include max-screen(767px) {
                    font-size: 12px;
                }
            }
            .img_step {
                margin: 0 0 18px;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                border: 1px solid #DDDDDD;
                margin: 0 0 60px;
                @include max-screen(767px) {
                    display: block;
                    border: none;
                    margin: 0 0 15px;
                }
                tbody {
                    @include max-screen(767px) {
                        display: block;
                    }
                }
                tr {
                    border-bottom: 1px solid #DDDDDD;
                    @include max-screen(767px) {
                        border: none;
                        display: block;
                    }
                    th,td {
                        padding: 15px 25px;
                        @include max-screen(767px) {
                            display: block;
                            width: initial;
                            width: inherit;
                            margin: 0 -25px;
                        }
                    }
                    th {
                        width: 263px;
                        background: #FAE9E9;
                        text-align: left;
                        font-weight: normal;
                        border-left: 1px solid #DDDDDD;
                        border-right: 1px solid #DDDDDD;
                        @include max-screen(767px) {
                            border: none;
                            width: initial;
                            width: inherit;
                            padding: 5px 20px;
                        }
                        span {
                            color: #D22522;
                            display: inline-block;
                            margin-right: 10px;
                            @media screen and (-webkit-min-device-pixel-ratio:0) {
                                width: 60px;
                            }
                        }
                    }
                    td {
                        border-right: 1px solid #DDDDDD;
                        @include max-screen(767px) {
                            border: none;
                        }
                        input {
                            width: 100%;
                            height: 40px;
                            border: 1px solid #DDDDDD;
                            background:  #FFF;
                            padding: 0 10px;

                        }
                        div.address {
                            margin: 0 0 10px;
                            @include max-screen(767px) {
                                margin: 0 0 5px;
                            }
                            .size_2 {
                                width: 135px;
                            }
                            span {
                                font-size: 12px;
                            }
                            span.txt_sm {
                                padding: 5px 0 0 17px;
                                @include max-screen(767px) {
                                    display: block;
                                    padding: 5px 0 0 17px;
                                }
                            }
                        }
                        div.code {
                            input {
                                width: 350px;
                                @include max-screen(767px) {
                                    width: 100%;
                                }
                            }
                            span {
                                display: block;
                                padding: 5px 0 5px;
                                font-size: 12px;
                            }
                            &:last-child {
                                span {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            .page_btn {
                clear: both;
                @include max-screen(767px) {
                    font-size: 15px;
                    height: 45px;
                    line-height: 45px;
                }

            }
            .contact_info {
                text-align: center;
                padding: 50px 0 0;
                font-size: 12px;
                @include max-screen(767px) {
                    padding: 30px 0 0;
                }

            }
        }
    }
    /*Login login2*/
    &.login2 {
        .bread {
            background: none;
            @include max-screen(767px) {
                padding-bottom: 0;
            }
        }
        .sec {

        }
        .sec_form {
            padding: 27px 0 75px ;
            @include max-screen(767px) {
                padding: 10px 0 20px;
            }
            table {
                margin: 0 0 20px;
                tr {
                    @include max-screen(767px) {
                        border-bottom: 1px solid #8e8e8e;
                        padding: 10px 25px;
                        margin: 0 -15px;
                    }

                    th {
                        background: #f6f6f6  ;
                        @include max-screen(767px) {
                            background: #FFF;
                            padding: 0 15px;
                        }
                        em {
                            font-style: normal;
                            position: relative;
                            @include max-screen(767px) {
                                padding-left: 20px;
                            }
                            &:before {
                                @include max-screen(767px) {
                                    display: block;
                                    content: "";
                                    width: 12px;
                                    height: 12px;
                                    background: #1E1E1E;
                                    position: absolute;
                                    left: 0;
                                    top: 1px;
                                }

                            }
                        }
                    }
                    td {
                        @include max-screen(767px) {
                            background: #FFF;
                            padding: 0 15px;
                        }
                    }
                }
            }
            .page_back {
                float: right;
                position: relative;
                margin: 0 0 60px;
                @include max-screen(767px) {
                    font-size: 11px;
                    margin: 0 0 30px;
                }
                &:before {
                    display: block;
                    content: "";
                    width: 7px;
                    height: 6px;
                    background: url(../img/common/icon/arrow_right02.svg) no-repeat;
                    background-size: 7px 6px;
                    position: absolute;
                    top: 9px;
                    left: -15px;
                    @include max-screen(767px) {
                        top: 7px;
                    }
                }
            }
        }
    }
    /*Login login3*/
    &.login3 {
        .bread {
            background: none;
            padding-bottom: 0;
        }
        .sec {
            padding: 65px 0 80px;
            @include max-screen(767px) {
                padding: 30px 0 30px;
            }
            h3 {
                font-size: 26px;
                font-weight: normal;
                color: $colortxt1;
                text-align: center;
                padding: 50px 0 25px;
                @include max-screen(767px) {
                    font-size: 19px;
                    padding: 25px 0 20px;
                }
            }
            div.login_alert {
                line-height: 28px;
                margin: 0 0 30px;
                @include max-screen(767px) {
                    line-height: 25px;
                }
            }
            div.contact_info {
                line-height: 28px;
                margin: 0 0 70px;
                @include max-screen(767px) {
                    line-height: 25px;
                    margin: 0 0 30px;
                }
            }
            .btn_back {
                width: 240px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                border: 1px solid #1E1E1E;
                margin: 0 auto;
                font-size: 15px;
                display: block;
                position: relative;
                @include max-screen(767px) {
                    width: 200px;
                }
                &:before {
                    display: block;
                    content: "";
                    width: 12px;
                    height: 12px;
                    background: url(../img/common/icon/arrow_left_black.svg);
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    margin-top: -6px;
                }
            }
        }
    }

    /*Login fotget*/
    &.forget {
        .header02 {
            position: static;
        }
        .footer {
            @include max-screen(767px) {
                margin-top: 15px;
            }
        }
        #wrap {
            margin: 0;
        }
        .header02 .logo {
            float: none;
            width: 100%;
            text-align: center;
            padding: 60px 0 0;
            @include max-screen(767px) {
                padding: 90px 0 0;
            }
            img {
                width: 270px;
                height: auto;

            }
            span {
                display: block;
                padding: 7px 0 0;
            }
        }

        .sec01 {
            padding: 80px 0 90px;
            @include max-screen(767px) {
                padding: 30px 0 25px;
            }
            .forget_title {
                font-size: 20px;
                font-weight: normal;
                background: rgba(197, 217, 221, 0.3);
                padding: 7px 10px;
                margin: 0 -10px 50px;
                @include max-screen(767px) {
                    font-size: 14px;
                    margin-bottom: 25px;
                    font-weight: 600;
                }

                .inner {
                    padding: 0;
                    max-width: 800px;
                    @include max-screen(767px) {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
            h3 {
                font-size: 14px;
                text-align: center;
                margin: 0 0 30px;
                @include max-screen(767px) {
                    text-align: left;
                }

            }
            .inner {
                max-width: 800px;
                width: 100%;
                margin: 0 auto;

            }
            table {
                max-width: 500px;
                width: 100%;
                margin: 0 auto 75px;
                @include max-screen(767px) {
                    display: block;
                    margin: 0 auto 25px;
                }
                tbody {
                    @include max-screen(767px) {
                        display: block;
                    }
                }
                tr {
                    @include max-screen(767px) {
                        display: block;
                        margin: 0 0 20px;
                    }
                    &:nth-child(2) {
                        margin: 0 0 5px;
                    }
                    th,
                    td {
                        padding: 7px 10px;
                        vertical-align: top;
                        font-weight: normal;
                        @include max-screen(767px) {
                            display: block;
                            padding: 0;
                            width: 100% !important;
                        }
                    }
                    th {
                        width: 120px;
                        padding-top: 20px;
                        text-align: left;
                        @include max-screen(767px) {
                            padding: 0 0 6px;
                        }
                    }
                    td {
                        span {
                            display: block;
                            padding: 5px 0 0;
                            font-size: 12px;
                            @include max-screen(767px) {
                                font-size: 11px;
                            }
                        }
                        input {
                            width: 100%;
                            height: 40px;
                            border: 1px solid #DDDDDD;
                            background: #FFF;
                            padding: 0 10px;
                        }
                    }
                }
            }
            button.btn-black {
                background: #1E1E1E;
                @include max-screen(767px) {
                    width: 200px;
                    height: 45px;
                    line-height: 45px;
                }
            }
            .contact_info {
                text-align: center;
                padding: 50px 0 0;
                font-size: 12px;
            }
        }
        &.forget_step2 {
            .sec01 {
                padding-bottom: 76px;
                @include max-screen(767px) {
                    padding-bottom: 30px;
                }
            }
            h3 {
                margin-bottom: 50px;
                @include max-screen(767px) {
                    text-align: left;
                    margin-bottom: 25px;
                }
            }

            table {
                max-width: 800px;
                width: 100%;
                border-collapse: collapse;
                border: 1px solid #DDDDDD;
                margin: 0 0 20px;
                @include max-screen(767px) {
                    border: none;
                }
                tr {
                    border: 1px solid #DDDDDD;
                    @include max-screen(767px) {
                        padding: 10px 0;
                        margin: 0;
                        border: none;
                        border-bottom: 1px solid #DDDDDD;
                    }

                    th {
                        width: 265px;
                        background: #f6f6f6  ;
                        padding: 15px 10px;
                        vertical-align: middle;
                        @include max-screen(767px) {
                            background: #FFF;
                            padding: 0 10px;
                            width: 100%;
                        }
                        em {
                            font-style: normal;
                            position: relative;
                            @include max-screen(767px) {
                                padding-left: 20px;
                            }
                            &:before {
                                @include max-screen(767px) {
                                    display: block;
                                    content: "";
                                    width: 12px;
                                    height: 12px;
                                    background: #1E1E1E;
                                    position: absolute;
                                    left: 0;
                                    top: 1px;
                                }

                            }
                        }
                    }
                    td {
                        vertical-align: middle;
                        @include max-screen(767px) {
                            background: #FFF;
                            padding: 0 10px;
                        }
                    }
                }
            }
            .page_back {
                float: right;
                position: relative;
                margin: 0 0 60px;
                @include max-screen(767px) {
                    font-size: 11px;
                    margin: 0 0 30px;
                }
                &:before {
                    display: block;
                    content: "";
                    width: 7px;
                    height: 6px;
                    background: url(../img/common/icon/arrow_right02.svg) no-repeat;
                    background-size: 7px 6px;
                    position: absolute;
                    top: 9px;
                    left: -15px;
                    @include max-screen(767px) {
                        top: 7px;
                    }
                }
            }
            .page_btn {
                clear: both;
            }
            .contact_info {
                @include max-screen(767px) {
                    padding: 30px 0 0;
                }
            }
        }
        &.forget_step3 {
            .sec01 {
                padding: 75px 0 90px;
                 @include max-screen(767px) {
                    line-height: 25px;
                    padding: 40px 0 40px;
                }
                .forget_title {
                    margin-bottom: 0;
                }
                h3 {
                    font-size: 26px;
                    letter-spacing: 3px;
                    font-weight: 300;
                    color: $colortxt1;
                    text-align: center;
                    padding: 40px 0 0;
                    @include max-screen(767px) {
                        font-size: 19px;
                        padding: 25px 0 0;
                        margin-bottom: 20px;
                    }
                }
                div.alert {
                    line-height: 28px;
                    margin: 0 0 30px;
                    @include max-screen(767px) {
                        line-height: 25px;
                    }
                }
                div.contact_info {
                    line-height: 28px;
                    margin: 30px 0 80px;
                    padding: 0;
                    font-size: 14px;
                    text-align: left;
                    @include max-screen(767px) {
                        line-height: 25px;
                        margin: 20px 0 60px;
                    }
                }
                .btn_back {
                    width: 240px;
                    height: 45px;
                    line-height: 45px;
                    text-align: center;
                    border: 1px solid #1E1E1E;
                    margin: 0 auto;
                    font-size: 15px;
                    display: block;
                    position: relative;
                    @include max-screen(767px) {
                        width: 200px;
                    }
                    &:before {
                        display: block;
                        content: "";
                        width: 12px;
                        height: 12px;
                        background: url(../img/common/icon/arrow_left_black.svg);
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        margin-top: -6px;
                    }
                }
            }
        }

    }
}






