@charset 'UTF-8';

/* for header ------------------------------- */
.header {
	@include transition_c(0.3s ease all);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;
	width: 100%;
	background: #fff;
	min-height: 173px;
	h1 {
		color: #a5a5a5;
		font-size: 12px;
		background: $colortext;
		padding: 4px 10px 3px;
		span {
			display: block;
			margin: 0 auto;
			max-width: $widthcm;
		}
	}
	.inner_head {
		padding: 35px 10px 0;
	}
	.inside {
		margin: 0 auto;
		max-width: $widthcm;
	}
	.logo {
		display: inline-block;
		vertical-align: middle;
		width: 70%;
		color: #787878;
		font-size: 12px;
		a {
			color: #787878;
		}
		img {
			width: 200px;
			height: 47px;
			margin-right: 15px;
		}
	}
	.button {
		display: inline-block;
		vertical-align: middle;
		width: 30%;
		font-size: 12px;
		letter-spacing: 0.5px;
		text-align: right;
		margin-top: -30px;
		a {
			display: inline-block;
			min-width: 140px;
			padding: 5px 10px 4px;
			text-align: center;
			border: 1px solid #d2d2d2;
			span {
				display: inline-block;
				padding-left: 22px;
				background: url(../img/common/icon/ico_email_grey.svg) no-repeat 0 50%;
				@include bgsize(13px 10px);
			}
		}
	}

}
.header02 {
	min-height: auto;
	.inner_head {
		padding: 0 10px 0;
	}
	.logo {
		width: 65%;
		float: left;
		padding: 35px 0 0;
		@include max-screen(767px) {
			float: none;
			padding: 15px 0 0;
			width: 100%;
		}
		a {
			opacity: 1;
		}
		img {
			@include max-screen(767px) {
				width:  140px;
				height: auto;
			}
			@include max-screen(320px) {
				width:  80px;
			}
		}
		span {
			@include max-screen(767px) {
				font-size: 11px;
			}
		}
	}
	&__phone {
		padding: 20px 0 0;
		display: block;
		text-align: right;
		position: absolute;
		right: 80px;
		span {
			font-size: 12px;
			color: $colortxt1;
			display: inline-block;
			position: relative;
			margin-right: 18px;
			top: -1px;
			&:after {
				display: block;
				content: "";
				width: 6px;
				height: 5px;
				background: url(../img/common/header/arrow_right.svg) no-repeat;
				background-size: 6px 5px;
				position: absolute;
				top: 7px;
				right: -10px;
			}
		}
		a {
			color: $colortxt1;
			font-size: 18px;
			line-height: 18px;
			display: inline-block;
			position: relative;
			padding-left: 20px;
			font-family: 'Montserrat', sans-serif;
			&:after {
				display: block;
				content: "";
				width: 14px;
				height: 14px;
				background: url(../img/common/header/icon_phone.svg) no-repeat;
				background-size: 14px 14px;
				position: absolute;
				top: 3px;
				left: 0;
			}
		}
	}
	&__phone_sp {
		position: absolute;
		top: 0;
		right: 0;
		img {
			width: 50px;
		}
	}
}
.gNaviWrap {
	padding: 0 10px;
	min-height: 62px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		border-bottom: 2px solid #d2d2d2;
	}
	.gNavi {
		float: left;
		width: 72%;
		margin-top: 25px;
		a {
			display: block;
		}
		> li {
			display: inline-block;
			vertical-align: top;
			width: auto;
			font-size: 15px;
			margin-right: 35px;
			// position: relative;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 3;
				width: 100%;
				height: 2px;
				background: #d2d2d2;
			}
			> a {
				position: relative;
				z-index: 1000;
				min-height: 38px;
				&:after {
					@include triangle;
					display: none;
				}
				&:before {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 2px;
				}
				&:hover {
					@include opacity(100);
					&:before {
						border-bottom: 2px solid $colortext;
					}
				}
			}
			&.active {
				> a {
					&:after {
						display: block;
					}
					&:before {
						border-bottom: 2px solid $colortext;
					}
				}
			}
			&:last-child {
				margin-right: 15px;
			}
		}
		.navSub {
			display: none;
			position: absolute;
			left: 0;
			top: 63px;
			z-index: 999;
			width: 100%;
			font-size: 14px;
			letter-spacing: 0.5px;
			padding: 15px 10px 0;
			background: #fff;
			border-bottom: 1px solid #d2d2d2;
			> ul {
				text-align: center;
				> li {
					display: inline-block;
					vertical-align: top;
					width: auto;
					margin: 0 16px 10px;
					a {
						color: #4b4b4b;
					}
				}
			}
		}
		@include max-screen(860px) {
			> li {
				margin-right: 15px;
			}
		}
	}
	.header02__phone {
		display: none;
	}
	.my_menu {
		float: right;
		width: 28%;
		font-size: 12px;
		letter-spacing: 0.5px;
		text-align: right;
		li {
			display: inline-block;
			vertical-align: top;
			width: 33.333333%;
			border-right: 1px solid #d2d2d2;
			a {
				display: block;
				min-height: 50px;
				padding: 30px 0 0;
				text-align: center;
				background: url(../img/common/icon/ico_mypage_grey.svg) no-repeat 50% 5px;
				@include bgsize(10px 17px);
			}
			&.item1 {
				border-left: 1px solid #d2d2d2;
				a {
					background-image: url(../img/common/icon/ico_cart_grey.svg);
					@include bgsize(20px 15px);
					background-position: 50% 7px;
				}
			}
			&.item3 {
				a {
					background-image: url(../img/common/icon/ico_logout_grey.svg);
					@include bgsize(18px 19px);
				}
			}
		}
	}
}
.gNaviMypage {
	background: #00BBCC;
	display: none;
	ul {
		li {
			float: left;
			a {
				display: inline-block;
				font-size: 14px;
				color: #FFF;
				padding: 8px 30px;
			}
			&:first-child {
				a {
					padding-left: 0;
				}
			}
		}
	}
}

// #top .gNavi > li.gItem4,
#mypage .gNavi > li.gItem4 {position: relative;}
#guide .gNavi > li.gItem3,
#mypage .gNavi > li.gItem4 {
	&:before {
		background: $colortxt1;
	}
	> a:before {
		border-bottom: 2px solid $colortxt1;
	}
}

body:not(#guide) .gNavi > li.gItem4 form {position: relative; z-index: 9;
	&:after {content: ""; display: block; border-bottom: 2px solid $colortxt1; position: absolute; bottom: 0; left: 0; width: 100%;}
}

.form_search {
	margin-top: -10px;
	.txt_search {
		border: none;
		box-shadow: none;
		background: #fff;
		height: 48px;
		padding: 5px 5px 5px 0;
		width: 190px;
		&::-moz-placeholder {line-height: 38px;}
	}
	.btn_search {
		border: none;
		box-shadow: none;
		text-indent: -9999em;
		cursor: pointer;
		background: #fff url(../img/common/icon/ico_search_black.svg) no-repeat 50% 50%;
		@include bgsize(15px 16px);
	}
	@include max-screen(860px) {
		.txt_search {
			padding-right: 15px;
		}
	}
}

.header_sp {
	@include transition_c(0.3s ease);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;
	width: 100%;
	height: 60px;
	overflow: hidden;
	background: #fff;
	.memLink {
		position: fixed;
		left: 0;
		top: 60px;
		z-index: 1000;
		width: 100%;
		min-height: 40px;
		background: $colortext;
		text-align: center;
		display: none;
		&:before {
			content: '';
			position: absolute;
			left: 50%;
			top: 10px;
			width: 1px;
			height: calc(100% - 20px);
			background: #616161; display: block;
		}
		li {
			float: left;
			width: 50%;
			a {
				display: block;
				color: #fff;
				font-size: 12px;
				min-height: 40px;
				padding-top: 11px;
				span {
					white-space: nowrap;
					display: inline-block;
					padding-left: 16px;
					background: url(../img/common/icon/ico_mypage_grey.svg) no-repeat 0 3px;
					@include bgsize(8px 13px);
				}
			}
			&.item2 {
				a {
					span {
						padding-left: 20px;
						background: url(../img/common/icon/ico_logout_grey.svg) no-repeat 0 2px;
						@include bgsize(13px 13px);
					}
				}
			}
		}
	}
}

.cartBtn {
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000;
	width: 50px;
	height: 60px;
	text-align: center;
	cursor: pointer;
	font-size: 9px;
	a {
		display: block;
		width: 100%;
		height: 100%;
		padding-top: 32px;
		background: url(../img/common/icon/ico_cart_black.svg) no-repeat 50% 15px;
		@include bgsize(22px 17px);
		&:hover {
			@include opacity(100);
		}
	}
}

/* .navBtn
------------------------------------*/
.navBtn {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;
	width: 50px;
	height: 60px;
	padding-top: 21px;
	text-align: center;
	cursor: pointer;
	@include transition_all;
	.hamburMenu {
		display: block;
		width: 24px;
		height: 24px;
		margin: 0 auto;
	}
	.hamburMenu a {
		display: block;
		overflow: hidden;
		position: relative;
		margin: 0 auto;
		width: 24px;
		height: 24px;
		@include transition_all;
		&:hover {
			@include opacity(100);
		}
	}
	.hamburMenu a span {
		display: block;
		position: absolute;
		left: 0;
		width: 24px;
		height: 2px;
		background-color: $colortext;
		@include transition_all;
	}
	.hamburMenu a .ham { top: 0; }
	.hamburMenu a .bur { top: 8px; }
	.hamburMenu a .ger { top: 16px; }
}

/* .layerMenuOn .layerMenu
------------------------------------*/
.layerMenu {
	position: fixed;
	top: 60px;
	left: 0;
	z-index: 999;
	width: 100%;
	height: calc(100vh - 100px);
	@include opacity(0);
	overflow: hidden;
	visibility: hidden;
	background: #fff;
	@include transition_all;
	.layerIn {
		position: relative;
		padding: 0 10px 100px;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		font-size: 15px;
		.layerNav {
			border-bottom: 1px solid $colortext;
			> li {
				display: block;
				border-bottom: 1px solid #bbb;
				padding: 0 5px;
				a {
					display: block;
					padding: 7px 10px 6px;
					&.new_win {
						background: url(../img/common/icon/ico_win.svg) no-repeat right 6px center;
						@include bgsize(17px 13px);
					}
				}
				&:last-child {
					border-bottom: none;
				}
				&.hasSub {
					> p {
						position: relative;
						a {
							width: 87%;
						}
					}
				}
				&.active {
					> p {
						background: $colortext;
						margin: 0 -5px 0;
						a {
							color: #fff;
							padding-left: 15px;
						}
					}
				}
				.accor {
					display: inline-block;
					position: absolute;
					right: 0;
					top: 0;
					z-index: 10;
					width: 30px;
					height: 100%;
					color: #fff;
					cursor: pointer;
					&:before {
						content: '+';
						position: absolute;
						right: 0;
						top: 20%;
						width: 100%;
						text-align: center;
						font-size: 20px;
						font-weight: bold;
						line-height: 1;
						color: #00bbcc;
					}
					&.active {
						&:before {
							content: '-';
							font-size: 26px;
							top: 10%;
							right: 5%;
						}
					}
				}
				.navSub {
					display: none;
					ul {
						padding: 10px 0;
					}
				}
			}
		}
		.my_info {
			background: #f0f5f6;
			border-bottom: 2px solid #d2d2d2;
			margin: 0 -10px;
			padding: 15px 10px 10px;
			> li {
				padding: 0 15px;
				a {
					padding: 7px 0 6px;
				}
			}
			.point {
				font-size: 12px;
				letter-spacing: 1px;
				padding-bottom: 25px;
				span {
					letter-spacing: 0.5px;
					margin-right: 65px;
				}
			}
		}
		.blank_link {
			text-align: center;
			li {
				margin-bottom: 10px;
				img {
					width: 100%;
				}
			}
		}
		.social {
			text-align: center;
			margin: 15px 0;
			li {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				margin: 0 15px;
				&.fb {
					img {
						width: 11px;
						height: 21px;
					}
				}
				&.insta {
					img {
						width: 21px;
						height: 21px;
					}
				}
			}
		}
	}
}

.layerMenuOn {
	height: 100vh; overflow: hidden;
	.navBtn {
		.hamburMenu {
			a {
				-moz-transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				transform: rotate(-45deg);
				span {
					background-color: $colortext;
				}
				.ham {
					top: 5px;
					-moz-transform: rotate(0deg) translateY(6px);
					-webkit-transform: rotate(0deg) translateY(6px);
					-ms-transform: rotate(0deg) translateY(6px);
					transform: rotate(0deg) translateY(6px);
				}
				.ger {
					top: 6px;
					-moz-transform: rotate(90deg) translateY(0px) translateX(5px);
					-webkit-transform: rotate(90deg) translateY(0px) translateX(5px);
					-ms-transform: rotate(90deg) translateY(0px) translateX(5px);
					transform: rotate(90deg) translateY(0px) translateX(5px);
				}
				.bur {
					opacity: 0;
				}
			}
		}
	}
	.layerMenu {
		visibility: visible;
		@include opacity(100);
		@include transition_all;
	}
}


/* has mypage menu */
.has_mypage {
	.header_sp {
		height: 100px;
		.memLink {
			display: block;
		}
	}
	.layerMenu {
		top: 100px;
	}
	#wrap {
		@include max-screen(767px) {
			margin: 100px 0 0;
		}
	}
}

.header_sp {z-index: 999; overflow: visible;
	.layerMenu, .memLink, .cartBtn, .navBtn {position: absolute;}
	.logo02_sp {width: 115px; display: block; position: absolute; top: 30px; left: 50%; @include transform_c(translate(-50%,-50%));}
}
body {position: relative;}
.upHeader {
	.header, .header_sp {top: -220px;}
}

/* menuMypage SP*/
.menuMypage {
	padding: 0 25px 18px;
	.selectWrap {
		position: relative;
		&:after {
			display: block;
			content: "";
			border: 7px solid transparent;
			border-top: 10px solid #8E8E8E;
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -3px;
		}
		select {
			width: 100%;
			height: 40px;
			line-height: 40px;
			background: none;
			border: 1px solid #DDDDDD;
			padding-left: 15px;
		}

	}
}
















