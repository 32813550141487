@charset "UTF-8";
@import "mixin";
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
	line-height: 0;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}
table img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}
p {
	margin: 0;
	padding: 0;
}
ol, ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
input, textarea {
	margin: 0;
	font-size: 100%;
	resize: none;
}
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 100%;
}
dl, dt, dd, th, td {
	margin: 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6 {
	/*font-size: 100%;*/
	font-weight: normal;
	margin: 0;
	padding: 0;
}
pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section, main {display: block;}
nav ul {list-style: none;}
textarea, button {border: 0; box-shadow: none; -webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; -o-appearance: none; appearance: none;
	&:focus {outline: none; box-shadow: none;}
}
textarea {border: 1px solid #DDDDDD;}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
	outline: none;
	@include transition_all;
}
a:link {
	color: $colortext;
	text-decoration: none;
}
a:visited {
	color: $colortext;
	text-decoration: none;
}
a:hover {
	text-decoration: none;
	@include opacity(60);
	@include transition_all;
}
a:active {
	color: $colortext;
	text-decoration: none;
}
a {
	@include max-screen(767px) {
		&:hover {
			@include opacity(100);
		}
	}
}

/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
.ffN {font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;}
.ffM {font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;}
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

.contentcms img {
	max-width:100%;
	height:auto;
	max-height:100%;
	width:100%;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
        white-space: nowrap;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}
/*-----------------------------------------------------------
PAGE BOOT
------------------------------------------------------------*/
/* MovableType */
div#pageBoot {
	margin: 35px 0 60px 0;
	text-align: center;
}
div#pageBoot a {
	color: #aaa;
	text-decoration: none !important;
}
span.current_page {
	color: #FFFFFF;
	padding: 5px;
	border: 1px solid #aaa;
	background-color: #aaa;
}
a.link_page {
	padding: 5px;
	border: 1px solid #aaa !important;
}
a.link_page:hover {
	padding: 5px;
	border: 1px solid #aaa;
	background-color: #aaa;
	color: #FFFFFF !important;
}
/* WordPress */
.wp-pagenavi {
	clear: both;
	text-align: center;
	font-family:Arial;
	font-size:14px;
}
.wp-pagenavi span, .wp-pagenavi a {
	line-height:30px;
	display:inline-block;
	margin:0 4px;
	color:#666;
	text-align:center;
}
.wp-pagenavi a, .wp-pagenavi span.current {
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	width:30px;
	height:30px;
	background:#ededed;
}

.wp-pagenavi a:hover, .wp-pagenavi span.current {
	background: #1489c3;
	color:#fff;
}
.wp-pagenavi .pages {
	margin-right:15px;
}
/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
body {
	width: 100%;
	color: $colortext;
	margin: 0;
	padding: 0;
	line-height: 1.8;
	letter-spacing: 1px;
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-size: 14px;
	height: 100%;
	word-break: break-word;
	-webkit-font-feature-settings: "palt";
	-moz-font-feature-settings:    "palt";
	-ms-font-feature-settings:     "palt";
	font-feature-settings:         "palt";
	@include max-screen(767px) {
		letter-spacing: 0.5px;
	}
}

/*-----------------------------------------------------------
 Container/Maincontent
------------------------------------------------------------*/
#layout {
	overflow: hidden;
}
#wrap {
	margin: 173px 0 0;
	@include max-screen(767px) {
		margin: 60px 0 0;
	}
}
#mainContent {
	padding: 0 10px;
}
.spaceLR {
	@include spaceLR;
}
.secInner {
	margin: 0 auto;
	max-width: $widthcm;
}


/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.sp {
	@include min-screen(768px) {
		display: none!important;
	}
}
.pc {
	@include max-screen(767px) {
		display: none!important;
	}
}
img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}
.imgMax { width: 100%; }

.bread {
	color: #787878;
	font-size: 12px;
	padding: 0 10px;
	margin-bottom: 40px;
	ul {
		margin: 0 auto;
		max-width: $widthcm;
		li {
			display: inline-block;
			vertical-align: top;
			width: auto;
			margin-right: 3px;
			@include max-screen(767px) {display: inline;}
			a {
				display: inline-block;
				color: #787878;
				text-decoration: underline;
				@include notransition;
				padding-right: 10px;
				position: relative;
				&:before {
					@include arrow;
					left: auto;
					right: 0;
					@include rotate_skew(135deg, 10deg, 10deg);
				}
				&:hover {
					text-decoration: none;
					@include opacity(100);
					@include notransition;
				}
			}
		}
	}
	@include max-screen(767px) {
		font-size: 11px;
		margin-bottom: 15px;
		ul {
			li {
				a {
					&:before {
						width: 6px;
						height: 6px;
						margin-top: -3px;
					}
				}
			}
		}
	}
}

.bHead {
	background: $colortext;
	color: #fff;
	font-size: 22px;
	letter-spacing: 4px;
	text-align: center;
	min-height: 100px;
	margin-bottom: 15px;
	position: relative;
	span {
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		text-align: center;
		@include transformY;
	}
	@include max-screen(767px) {
		font-size: 20px;
		min-height: 68px;
		margin-bottom: 10px;
	}
}

.mHead {
	color: #fff;
	font-size: 26px;
	line-height: 1.5;
	letter-spacing: 3px;
	background: $colorbg2;
	padding: 18px 10px;
	margin: 0 -10px 20px;
	h3 {
		margin: 0 auto;
		max-width: $widthcm;
	}
	@include max-screen(767px) {
		font-size: 16px;
		letter-spacing: 2px;
		padding: 9px 25px;
		margin-bottom: 15px;
	}
}

.sHead {
	font-size: 26px;
	line-height: 1.5;
	letter-spacing: 3px;
	text-align: center;
	margin-bottom: 35px;
	@include max-screen(767px) {
		font-size: 20px;
		letter-spacing: 2px;
		margin-bottom: 20px;
	}
}

.plus {
	&:before, &:after {
		content: '';
		position: absolute;
		right: 17px;
		top: 50%;
		z-index: 2;
		width: 13px;
		height: 3px;
		background: #8e8e8e;
	}
	&:after {
		right: 22px;
		width: 3px;
		height: 13px;
		margin-top: -5px;
	}
	&.active {
		&:after { display: none; }
	}
}

.product_list {
	line-height: 1.5;
	letter-spacing: 0.2px;
	margin: 0 -5px;
	li {
		float: left;
		width: 20%;
		padding: 0 5px;
		margin-bottom: 70px;
		text-align: center;
		.pic {
			display: block;
			margin: 20px 0 10px;
			img { display: inline-block; }
		}
		.type {
			display: block;
			color: #fff;
			font-size: 10px;
			text-align: center;
			margin-top: 10px;
			span {
				display: inline-block;
				background: #7189c4;
				padding: 1px 10px 0;
				@include radius(50px);
			}
			&.type2 {
				span {
					background: #e88400;
				}
			}
		}
		.desc {
			display: block;
			padding: 0 10px 20px;
		}
		.text1 {
			display: block;
			color: $colortxt1;
			font-size: 12px;
			margin-bottom: 6px;
		}
		.text2 {
			display: block;
			font-size: 15px;
			margin-bottom: 8px;
		}
		.price {
			display: block;
			font-size: 12px;
			line-height: 1.4;
			span {
				display: block;
				font-size: 15px;
			}
		}
		.inside {
			display: block;
			border: 1px solid #fff;
			&:hover {
				border: 1px solid #00a3c2;
				@include opacity(100);
			}
		}
	}
	@include min-screen(768px) {
		li {
			.text1 {
				.type { display:none; }
			}
		}
	}
	@include max-screen(767px) {
		line-height: 1.5;
		letter-spacing: 0.2px;
		margin: 0;
		li {
			float: none;
			width: 100%;
			padding: 10px;
			margin-bottom: 0;
			text-align: left;
			border-bottom: 1px solid #8e8e8e;
			.pic {
				display: inline-block;
				vertical-align: middle;
				width: 80px;
				margin: 0 10px 0 0;
				text-align: center;
				.type { display: none; }
			}
			.desc {
				display: inline-block;
				vertical-align: middle;
				width: calc(100% - 100px);
				padding: 0;
			}
			.text1 {
				font-size: 11px;
				margin-bottom: 6px;
				.text {
					display: inline-block;
					vertical-align: middle;
					margin: 0 6px 4px 0;
				}
				.type {
					display: inline-block;
					vertical-align: middle;
					margin: 0 4px 4px 0;
				}
			}
			.text2 {
				font-size: 14px;
				font-weight: bold;
				line-height: 1.4;
				padding-right: 10px;
			}
			.price {
				font-size: 11px;
				span {
					display: inline-block;
					font-size: 11px;
					margin-right: 1px;
				}
			}
			.inside {
				border: none; position: relative;
				&:hover {
					border: none;
				}
				&:after {content: ""; display: block; width: 8px; height: 8px; border-top: 2px solid #8e8e8e; border-right: 2px solid #8e8e8e; @include transform_c(rotate(45deg)); position: absolute; top: 50%; right: 5px; margin-top: -2px;}
			}
		}
	}
}


.page_btn {
    max-width: 500px;
    width: 100%;
    background: #1E1E1E;
    height: 62px;
    line-height: 62px;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    border: none;
    position: relative;
    @include max-screen(767px) {
        font-size: 15px;
        height: 45px;
        line-height: 45px;
    }
    &:hover {opacity: 0.8;}
    &:after {
        display: block;
        content: "";
        width: 8px; height: 8px; border-top: 2px solid #fff; border-right: 2px solid #fff; @include transform_c(rotate(45deg));
        position: absolute; top: 50%; right: 15px; margin-top: -5px;
        // width: 12px;
        // height: 12px;
        // background: url(../img/common/icon/arrow_wi.svg);
        // position: absolute;
        // top: 50%;
        // right: 10px;
        // margin-top: -6px;
    }
    &.btn-blue {
        background: $colortxt1;
        color: #FFF;
    }
    &.btn-black {
        background: #1E1E1E;
    }
}


.voice_list {
	line-height: 1.6;
	margin: 0 -10px;
	.item {
		float: left;
		width: 33.333333%;
		padding: 0 10px;
		.inside {
			display: block;
			background: #fff;
			border: 2px solid #d65fa5;
			padding: 15px;
		}
		.pic {
			display: block;
			float: left;
			width: 80px;
			margin-right: 20px;
		}
		.desc {
			display: block;
			overflow: hidden;
		}
		.text1 {
			display: block;
			font-size: 15px;
			font-weight: bold;
			line-height: 1.5;
		}
		.star {
			display: block;
			color: $colortxt2;
			margin-bottom: 2px;
		}
		.text2 {
			color: $colortxt2;
			font-size: 12px;
		}
		.text3 {
			clear: both;
			display: block;
			font-size: 12px;
			text-align: justify;
			margin-top: 10px;line-height: 1.8;
		}
	}
	@include max-screen(767px) {
		margin: 0 -2px;
		.item {
			padding: 0 2px;
			.pic {
				margin-right: 10px;
			}
			.text1 {
				font-size: 14px;
				margin-bottom: 2px;
			}
			.star {
				font-size: 12px;
			}
			.text3 {line-height: 1.5;}
		}
	}
}
.modal {
    @include max-screen(767px) {
        padding: 0 25px;
    }
    .modal-dialog {
        background: #FFF;
        max-width: 700px;
        width: 100%;
        margin: 5% auto 0;
        @include max-screen(767px) {
            margin: 25px auto 0;
        }
    }
    .modal-content {
        text-align: center;
        padding: 65px 10px ;
        border: none;
        @include max-screen(767px) {
            padding: 25px 10px;
        }
        &.modal-status {
            padding: 130px 0 90px;
            @include max-screen(767px) {
                padding: 25px 10px;
            }
            .modal_btn {
                .btn_cancel {
                    height: 62px;
                    line-height: 62px;
                    @include max-screen(767px) {
                        height: 45px;
                        line-height: 45px;
                    }
                }
            }
        }
        h3 {
            font-size: 18px;
            font-weight: normal;
            @include max-screen(767px) {
                font-size: 14px;
            }
        }
        .modal_product {
            margin: 65px 0 30px;
            @include max-screen(767px) {
                margin: 20px 0 10px;
            }
            h4 {
                font-size: 22px;
                font-weight: 600;
                margin: 50px 0 20px;
                @include max-screen(767px) {
                    font-size: 14px;
                    margin: 20px 0 5px;
                }
            }
            img {
                width: 150px;
                @include max-screen(767px) {
                    width: 80px;
                }
            }
            p {
                font-size: 14px;
                margin: 0 0 10px;
                @include max-screen(767px) {
                    font-size: 11px;
                    margin: 0 0 0px;
                }
            }
            span {
                font-size: 14px;
                display: block;
                @include max-screen(767px) {
                    font-size: 11px;
                }
            }
        }
        .modal_status {
            margin: 90px 0 90px;
            @include max-screen(767px) {
                margin: 40px 0 40px;
            }
            img {
                width: 138px;
                @include max-screen(767px) {
                    width: 68px;
                }
            }
        }
        .modal_status02 {
            margin: 160px 0 188px;
            @include max-screen(767px) {
                margin: 40px 0 40px;
            }
        }
        .modal_status03 {
            margin: 140px 0 150px;
            @include max-screen(767px) {
                margin: 40px 0 40px;
            }
        }
        .modal_warning {
            text-align: left;
            padding: 0 90px 30px;
            @include max-screen(767px) {
                padding: 0 10px 20px;
            }
            .im {
                text-align: center;
                margin: 0 0 23px;

                img {
                    width: 130px;
                    @include max-screen(767px) {
                        width: 65px;
                    }

                }
            }
            h2 {
                font-size: 18px;
                text-align: center;
                margin: 0 0 23px;
                @include max-screen(767px) {
                    font-size: 14px;
                    margin: 0 0 15px;
                }
            }
            h3 {
                font-size: 13px;
                color: #D22522;
                font-weight: normal;
                margin: 0 0 15px;
            }
            p {
                margin: 0 0 30px;
                span {
                    display: inline-block;
                    background: #A1B2BA;
                    padding: 3px 5px;
                    color: #FFF;
                    font-size: 11px;
                }
                em {
                    font-size: 19px;
                    padding-left: 10px;
                    font-style: normal;
                    position: relative;
                    top: 2px;
                }
                small {
                    font-size: 13px;
                }
            }
            h4 {
                font-size: 18px;
                font-weight: normal;
                padding: 3px 15px;
                margin: 0 0 20px;
                background: rgba(197, 217, 221, 0.3);
                @include max-screen(767px) {
                    font-size: 14px;
                    margin: 0 -10px 10px;
                }
            }
            span {
                font-size: 14px;
            }
        }
        .modal_btn {
            a {
                display: block;
                width: 500px;
                margin: 0 auto;
                cursor: pointer;
                @include max-screen(767px) {
                    width: 300px;
                }
                @include max-screen(320px) {
                    width: 280px;
                }
                img {
                    vertical-align: middle;
                    margin-right: 10px;
                    margin-top: -2px;
                }
                &.btn_action {
                    height: 62px;
                    line-height: 62px;
                    color: #FFF;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    @include max-screen(767px) {
                        height: 45px;
                        line-height: 45px;
                        font-size: 14px;
                    }
                }
                &.btn_cancel {
                    height: 42px;
                    line-height: 42px;
                    border: 1px solid #1E1E1E;
                    font-size: 15px;
                    font-weight: normal;
                    color: #1E1E1E;
                    @include max-screen(767px) {
                        height: 38px;
                        line-height: 38px;
                        font-size: 14px;
                    }
                }
                &.btn_blue {
                    background: #00BBCC;
                }
                &.btn_black {
                    background: #1E1E1E;
                }
            }
        }
    }
}






.voice_list {
	line-height: 1.6;
	margin: 0 -10px;
	.item {
		float: left;
		width: 33.333333%;
		padding: 0 10px;
		.inside {
			display: block;
			background: #fff;
			border: 2px solid #d65fa5;
			padding: 15px;
		}
		.pic {
			display: block;
			float: left;
			width: 80px;
			margin-right: 20px;
		}
		.desc {
			display: block;
			overflow: hidden;
		}
		.text1 {
			display: block;
			font-size: 15px;
			font-weight: bold;
			line-height: 1.5;
		}
		.star {
			display: block;
			color: $colortxt2;
			margin-bottom: 2px;
		}
		.text2 {
			color: $colortxt2;
			font-size: 12px;
		}
		.text3 {
			clear: both;
			display: block;
			font-size: 12px;
			text-align: justify;
			margin-top: 10px;
		}
	}
	@include max-screen(767px) {
		margin: 0 -2px;
		.item {
			padding: 0 2px;
			.pic {
				margin-right: 10px;
			}
			.text1 {
				font-size: 14px;
				margin-bottom: 2px;
			}
			.star {
				font-size: 12px;
			}
		}
	}
}

