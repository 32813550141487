@charset "UTF-8";
/* blog */

.leftCol {
	width:70%;
	float:left;
}
.rightCol {
	width:30%;
	float:right;
}

.cmsContent {
	-webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    word-wrap: break-word;
}
.cmsContent img{
	max-width:100%;
}
.pagingDt {
	position:relative;
	width:80%;
	margin:95px auto;
	font-size:14px;
}
.pagingDt a {
	text-decoration:underline;
}
.pagingDt .prev {
	background:url(../../img/blog/ico_prev.png) no-repeat left center;
	width:145px;
	float:left;
	padding-left:20px;	
}
.pagingDt .next {
	background:url(../../img/blog/ico_next.png) no-repeat right center;
	width:145px;
	float:right;
	padding-right:20px;	
}
.pagingDt .list {
	position:absolute;
	left:50%;
	top:50%;
	margin-left:-54px;
	margin-top:-9px;
}
