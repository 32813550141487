@charset 'UTF-8';

/* for footer ------------------------------- */
.toTop {position: fixed; display: block; bottom: 0; right: 0; z-index: 999;
	img {display: block; width: 60px; height: 60px;}
	@include max-screen(767px) {
		img {width: 45px; height: 45px;}
	}
}
.footer {
	margin-top: 40px;
	.social {
		text-align: center;
		margin-bottom: 30px;
		li {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			margin: 0 15px;
			&.fb {
				img {
					width: 11px;
					height: 21px;
				}
			}
			&.insta {
				img {
					width: 21px;
					height: 21px;
				}
			}
		}
	}
	.blank_link {
		text-align: center;
		margin-bottom: 25px;
		li {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			margin: 0 2px;
		}
	}
	.copyright {
		color: #4b4b4b;
		font-size: 12px;
		letter-spacing: 1px;
		text-align: center;
		padding: 0 10px 20px;
	}
	@include max-screen(767px) {
		.social {
			margin-bottom: 17px;
		}
		.blank_link {
			padding: 0 10px;
			margin-bottom: 5px;
			li {
				display: block;
				width: 100%;
				margin: 0 0 6px;
				img { width: 100%; }
			}
		}
		.copyright {
			font-size: 10px;
			padding: 0 10px 15px;
		}
	}
}

.guideSec {
	background: $colortxt1;
	padding: 42px 10px 23px;
	margin-bottom: 27px;
	.caption {
		color: #fff;
		font-size: 22px;
		letter-spacing: 4px;
		margin-bottom: 20px;
	}
	.guide {
		margin: 0 -1px 33px;
		li {
			float: left;
			width: 16.6666666667%;
			padding: 0 1px;
			color: #616161;
			font-size: 12px;
			line-height: 1.5;
			&:hover {opacity: 0.8;}
			> div {
				background: #fff;
				padding: 25px 13px 20px;
				> div {
					text-align: center;
					padding-bottom: 20px;
				}
			}
			.title {
				color: $colortext;
				font-size: 16px;
				line-height: 1.4;
				letter-spacing: 0;
				text-align: center;
				margin-bottom: 20px;
				span {
					font-size: 14px;
				}
			}
			&.item1 .icon {
				img { width: 49px; height: 66px; margin-top: 3px; }
			}
			&.item2 .icon {
				img { width: 68px; height: 53px; margin-top: 10px; }
			}
			&.item3 .icon {
				img { width: 69px; height: 46px; margin-top: 15px; }
			}
			&.item4 .icon {
				img { width: 35px; height: 67px; }
			}
			&.item5 .icon {
				img { width: 63px; height: 65px; }
			}
			&.item6 .icon {
				img { width: 60px; height: 73px; }
			}
		}
	}
	.link {
		text-align: center;
		li {
			display: inline-block;
			vertical-align: top;
			width: auto;
			margin: 0 12px;
			font-size: 13px;
			a {
				display: block;
				color: #fff;
				padding-left: 10px;
				position: relative;
				&:before {
					content: '-';
					position: absolute;
					left: 0;
					top: 50%;
					width: 5px;
					height: 1px;
					background: #fff;
					overflow: hidden;
				}
			}
		}
		.navSub {
			display: inline-block;
			vertical-align: top;
			width: auto;
			margin-right: -16px;
		}
	}
	@include max-screen(767px) {
		padding: 35px 0 15px;
		margin-bottom: 15px;
		.caption {
			font-size: 20px;
			text-align: center;
			margin-bottom: 15px;
		}
		.guide {
			margin: 0 5px 10px;
			li {
				float: left;
				width: 33.3333333333%;
				padding: 0 5px;
				margin-bottom: 10px;
				color: #616161;
				font-size: 12px;
				line-height: 1.5;
				> div {
					padding: 15px 0 10px;
					> div {
						padding-bottom: 15px;
					}
				}
				.title {
					font-size: 13px;
					letter-spacing: 0;
					margin-bottom: 0;
					span {
						font-size: 10px;
					}
				}
				&.item1 .icon {
					img { width: 36px; height: 49px; }
				}
				&.item2 .icon {
					img { width: 51px; height: 40px; }
				}
				&.item3 .icon {
					img { width: 52px; height: 34px; }
				}
				&.item4 .icon {
					img { width: 26px; height: 50px; }
				}
				&.item5 .icon {
					img { width: 54px; height: 50px; }
				}
				&.item6 .icon {
					img { width: 45px; height: 54px; }
				}
				.text { display: none; }
			}
		}
		.link {
			font-size: 14px;
			text-align: left;
			li {
				display: block;
				width: 100%;
				margin: 0;
				font-size: 14px;
				letter-spacing: 1px;
				border-top: 1px solid #fff;
				a {
					padding: 11px 25px 7px;
					&:before { display: none; }
					&.new_win {
						background: url(../img/common/icon/ico_win_white.svg) no-repeat right 15px center;
						@include bgsize(17px 13px);
					}
				}
				&.hasSub {
					> p {
						position: relative;
						a {
							width: 87%;
						}
					}
				}
				&.active {
					> p {
						background: #0089a3;
					}
				}
				.accor {
					display: inline-block;
					position: absolute;
					right: 10px;
					top: 0;
					z-index: 10;
					width: 30px;
					height: 100%;
					color: #fff;
					cursor: pointer;
					&:before {
						content: '+';
						position: absolute;
						right: 0;
						top: 20%;
						width: 100%;
						text-align: center;
						font-size: 20px;
						font-weight: bold;
						line-height: 1;
						color: #fff;
					}
					&.active {
						&:before {
							content: ''; display: block; font-size: 0; top: 20px; right: 10px;
							width: 13px; height: 3px; background: #fff;
						}
					}
				}
			}
			.navSub {
				display: none;
				width: 100%;
				margin: 0;
				ul {
					padding: 10px 0 5px;
					li {
						border: none;
						a {
							padding: 7px 25px 6px;
						}
					}
				}
			}
		}
	}
	@include max-screen(360px) {
		.guide {
			li {
				> div {
					padding-top: 10px;
					> div {
						padding-bottom: 8px;
					}
				}
				.title {
					font-size: 11px;
					span {
						font-size: 10px;
					}
				}
			}
		}
	}
}