@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Montserrat');
/* argument */
$border1: #c4c6c6 solid 1px;
$colortext: #1e1e1e;
$colortxt1: #00bbcc;
$colortxt2: #d65fa5;
$colortxt3: #d22522;
$colortxt4: #1a2aa3;
$colorbg1: #000;
$colorbg2: #00bbcc;
$widthcm: 1000px;
$widthcm2: 680px;

/* site style */
@mixin text-hide{
	overflow: hidden;
	text-indent: -9999px;
}


/* [ opacity ]
-------------------------------------------------*/
@mixin opacity($num){
	-ms-filter: alpha(opacity=$num); /* IE8 */
	opacity: $num/100;
	//display: inline-block\9;
}

/* [ display style ]
-------------------------------------------------*/
@mixin display-table{
	display: table;
	> *{
		display: table-cell;
		vertical-align: middle;
	}
}
@mixin height-match{
	display: block;
	text-align: center;
	> *{
		vertical-align: middle;
		display: inline-block;
	}
	&:before{
		content:"";
		height: 100%;
		vertical-align: middle;
		width: 0;
		display: inline-block;
	}
}


/* [ background-image ]
-------------------------------------------------*/
@mixin bgimg($url,$rep:repeat,$pos:left top,$color:none,$size:auto) {
	background-image: url($url);
	background-repeat: $rep;
	background-position: $pos;
	background-color: $color;
	background-size: $size;
}


/* [ space left and right ]
-------------------------------------------------*/
@mixin spaceLR {
	padding: 0 10px;
}


/* [ transition ]
-------------------------------------------------*/
@mixin transition_all {
	-moz-transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}


/* [ notransform ]
-------------------------------------------------*/
@mixin notransition {
	-moz-transition: none;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
}


/* [ radius ]
-------------------------------------------------*/
@mixin radius($num) {
	-moz-border-radius: $num;
	-webkit-border-radius: $num;
	-ms-border-radius: $num;
	border-radius: $num;
}


/* [ background-size ]
-------------------------------------------------*/
@mixin bgsize($size) {
	-moz-background-size: $size;
	-webkit-background-size: $size;
	-ms-background-size: $size;
	background-size: $size;
}


/* [ transformY ]
-------------------------------------------------*/
@mixin transformY {
	-moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


/* [ transformY ]
-------------------------------------------------*/
@mixin transformX {
	-moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}


/* [ transformXY ]
-------------------------------------------------*/
@mixin centering {
	-moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}


/* [ rotate ]
-------------------------------------------------*/
@mixin rotate($deg) {
	-moz-transform: rotate($deg);
	-webkit-transform: rotate($deg);
	-ms-transform: rotate($deg);
	transform: rotate($deg);
}


/* [ rotate_skew ]
-------------------------------------------------*/
@mixin rotate_skew($deg, $deg1, $deg2) {
	-moz-transform: rotate($deg) skew($deg1,$deg2);
	-webkit-transform: rotate($deg) skew($deg1,$deg2);
	-ms-transform: rotate($deg) skew($deg1,$deg2);
	transform: rotate($deg) skew($deg1,$deg2);
}


/* [ notransform ]
-------------------------------------------------*/
@mixin notransform {
	-moz-transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}


/* [ arrow ]
-------------------------------------------------*/
@mixin arrow {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	width: 7px;
	height: 7px;
	margin-top: -4px;
	border-left: 1px solid #787878;
	border-top: 1px solid #787878;
	@include rotate(135deg);
}


/* [ triangle ]
-------------------------------------------------*/
@mixin triangle {
	content: "";
	position: absolute;
	left: 50%;
	bottom: -10px;
	width: 0;
	height: 0;
	margin-left: -4px;
	border-bottom: 8px solid transparent;
	border-left: 8px solid $colortext;
	border-top: 8px solid transparent;
	@include rotate(90deg);
}


/* [ zoomImg ]
-------------------------------------------------*/
@mixin zoomImg {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}


/* [ for base ]
-------------------------------------------------*/
*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
html { -webkit-text-size-adjust: none; }
body {
	min-width: 320px;
	overflow-x: hidden;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	word-wrap: break-word;
}


/* [ for form ]
-------------------------------------------------*/
input:-webkit-autofill {
    -moz-box-shadow: inset 0 0 0px 9999px #fff;
	-webkit-box-shadow: inset 0 0 0px 9999px #fff;
	-ms-box-shadow: inset 0 0 0px 9999px #fff;
	box-shadow: inset 0 0 0px 9999px #fff;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
	background: #efefef;
}
textarea::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #bbb;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: normal;
	@include max-screen(767px) {

	}
}
textarea::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder { /* Firefox 19+ */
	color: #bbb;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: normal;
	@include max-screen(767px) {
	}
}
textarea:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder { /* IE 10+ */
	color: #bbb;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: normal;
	@include max-screen(767px) {

	}
}
textarea:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="text"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder { /* Firefox 18- */
	color: #bbb;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: normal;
	@include max-screen(767px) {
	}
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="tel"]::-webkit-outer-spin-button,
input[type="tel"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"],
input[type="tel"] {
    -moz-appearance: textfield;
}
select::-ms-expand {
    display: none;
}
input, textarea, button, select {
	outline: none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-ms-box-shadow: none;
	box-shadow: none;
	outline: none;
	resize: none;
	margin: 0;
}
input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
textarea, button, select {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}
textarea {
	display: block;
}
