.guide {
	#mainContent {
		padding-top: 2px;
		.anchorSec {
			margin-bottom: 37px;
			.anchorBox {
				float: left;
				width: 49.4%;
				margin-right: 1.2%;
				border: solid 1px #00bbcc;
				margin-bottom: 14px;
				height: 108px;
				position: relative;
				transition: 0.3s ease all;
				&:nth-child(2n) {
					margin-right: 0;
				}
				&:hover {opacity: 0.5;}
				.icon {
					width: 20%;
					text-align: center;
					display: inline-block;
					vertical-align: middle;
					line-height: 106px;
				}
				.content {
					width: 78%;
					display: inline-block;
					vertical-align: middle;
					margin-top: -10px;
					.title {
						font-size: 18px;
						margin-bottom: 5px;
						font-weight: normal;
						display: block;
					}
					.desc, p {
						font-size: 11px;
					}
				}
				&:before {
					position: absolute;
					content: '';
					bottom: 0;
					left: 0;
					width: 100%;
					height: 12px;
					background: #a1b2ba;
				}
				&:after {
					position: absolute;
					content: '';
					bottom: 3px;
					left: 49.5%;
					width: 8px;
					height: 8px;
					border-left: 2px solid #fff;
					border-top: 2px solid #fff;
					-moz-transform: rotate(225deg);
					-webkit-transform: rotate(225deg);
					-ms-transform: rotate(225deg);
					transform: rotate(225deg);
				}
			}
			.anchor1 {
				.icon {
					img {
						width: 45px;
						height: auto;
						margin-top: -12px;
					}
				}
			}
			.anchor2 {
				.icon {
					img {
						width: 59px;
						height: auto;
						margin-top: -6px;
					}
				}
			}
			.anchor3 {
				.icon {
					img {
						width: 60px;
						height: auto;
						margin-top: -6px;
					}
				}
			}
			.anchor4 {
				.icon {
					img {
						width: 31px;
						height: auto;
						margin-top: -6px;
					}
				}
			}
			.anchor5 {
				.icon {
					img {
						width: 55px;
						height: auto;
						margin-top: -6px;
					}
				}
			}
			.anchor6 {
				.icon {
					img {
						width: 49px;
						height: auto;
						margin-top: -15px;
					}
				}
			}
			@include max-screen(767px) {
				margin-bottom: 16px;
				.anchorBox {
					width: 31%;
					margin-right: 3.5%;
					text-align: center;
					height: 110px;
					border-bottom: none;
					&:nth-child(2n) {
						margin-right: 3.5%;
					}
					&:nth-child(3n) {
						margin-right: 0;
					}
					&:before {
						height: 11px;
					}
					&:after {
						bottom: 4.5px;
						left: 49.5%;
						width: 6px;
						height: 6px;
					}
					.icon {
						width: 100%;
						height: 63px;
						line-height: normal;
						margin-bottom: 13px;
						img {
							margin-top: 0;
						}
					}
					.content {
						width: 100%;
						.title {
							font-size: 13px;
							text-align: center;
							span {
								font-size: 10px;
							}
						}
					}
				}
				.anchor1 {
					.icon {
						img {
							width: 36px;
							height: auto;
							margin-top: 12px;
						}
					}
				}
				.anchor2 {
					.icon {
						img {
							width: 51px;
							height: auto;
							margin-top: 22px;
						}
					}
				}
				.anchor3 {
					.icon {
						img {
							width: 51px;
							height: auto;
							margin-top: 25px;
						}
					}
				}
				.anchor4 {
					.icon {
						img {
							width: 26px;
							height: auto;
							margin-top: 13px;
						}
					}
				}
				.anchor5 {
					.icon {
						img {
							width: 47px;
							height: auto;
							margin-top: 13px;
						}
					}
				}
				.anchor6 {
					.icon {
						img {
							width: 45px;
							height: auto;
							margin-top: 14px;
						}
					}
				}
			}
			@include max-screen(320px) {
				.anchorBox {
					.content {
						.title {
							font-size: 10px;
						}
					}
				}
			}
		}
		.bigTitle {
			font-size: 26px;
			background: #00bbcc;
			color: #fff;
			padding: 17px 0;
			margin: auto -10px;
			margin-bottom: 25px;
			span {
				max-width: 1000px;
				margin: 0 auto;
				display: block;
				padding-left: 52px;
				position: relative;
				&:before {
					position: absolute;
					top: 50%;
					left: 0;
					content: '';
					transform: translateY(-50%);
					-moz-transform: translateY(-50%);
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
				}
			}
			@include max-screen(767px) {
				font-size: 16px;
				padding: 8.5px 0;
				margin-bottom: 18px;
				span {
					padding-left: 51px;
				}
			}
		}
		.bigTitle1 {
			span {
				&:before {
					background-size: 31px 42px;
					width: 31px;
					height: 42px;
					background-image: url(../img/common/icon/ico_guide1_white.svg);
				}
			}
			@include max-screen(767px) {
				span {
					&:before {
						background-size: 18px 24.5px;
						width: 18px;
						height: 24.5px;
						left: 25px;
					}
					
				}
			}
		}
		.bigTitle2 {
			span {
				&:before {
					background-size: 38px 30px;
					width: 38px;
					height: 30px;
					background-image: url(../img/common/icon/ico_guide2_white.svg);
				}
			}
			@include max-screen(767px) {
				span {
					&:before {
						background-size: 23px 18px;
						width: 23px;
						height: 18px;
						left: 20px;
					}
				}
			}
		}
		.bigTitle3 {
			span {
				&:before {
					background-size: 38px 25px;
					width: 38px;
					height: 25px;
					background-image: url(../img/common/icon/ico_guide3_white.svg);
				}
			}
			@include max-screen(767px) {
				span {
					&:before {
						background-size: 23px 18px;
						width: 23px;
						height: 17px;
						left: 21px;
					}
				}
			}
		}
		.bigTitle4 {
			span {
				&:before {
					background-size: 19px 36px;
					left: 13px;
					width: 19px;
					height: 36px;
					background-image: url(../img/common/icon/ico_guide4_white.svg);
				}
			}
			@include max-screen(767px) {
				span {
					&:before {
						background-size: 12.5px 23.5px;
						width: 12.5px;
						height: 23.5px;
						left: 27px;
					}
				}
			}
		}
		.bigTitle5 {
			span {
				&:before {
					background-size: 33px 33px;
					width: 33px;
					height: 33px;
					background-image: url(../img/common/icon/ico_guide5_white.svg);
				}
			}
			@include max-screen(767px) {
				span {
					&:before {
						background-size: 18.5px 17.5px;
						width: 18.5px;
						height: 17.5px;
						left: 24.5px;
					}
				}
			}
		}
		.bigTitle6 {
			span {
				&:before {
					background-size: 29px 35px;
					width: 29px;
					height: 35px;
					background-image: url(../img/common/icon/ico_guide6_white.svg);
				}
			}
			@include max-screen(767px) {
				span {
					&:before {
						background-size: 19px 23px;
						width: 19px;
						height: 23px;
						left: 22.5px;
					}
				}
			}
		}
		.textIntro {
			line-height: 2;
			margin-bottom: 33px;
			letter-spacing: 1.4px;
			@include max-screen(767px) {
				padding: 0 15px;
				margin-bottom: 21px;
			}
		}
		.detail {
			letter-spacing: 1.4px;
			@include max-screen(767px) {
				padding: 0 15px;
			}
		}
		.smallText {
			font-size: 12px;
			margin: -14px 0 35px;
			line-height: 2;
			letter-spacing: 1.1px;
			@include max-screen(767px) {
				padding: 0 15px;
				font-size: 13px;
				margin-bottom: 20px;
			}
		}
		.steps {
			margin-bottom: 54px;
			.step {
				margin-bottom: 40px;
				&:last-child {
					margin-bottom: 0;
				}
				.titleStep {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: bold;
					color: #00bbcc;
					background: rgba(197, 217, 221, 0.3);
					padding: 4px 0 4px 20px;
					margin-bottom: 24px;
				}
				.demoImg {
					display: block;
					text-align: center;
					margin-top: 33px;
					img {
						border: solid 1px #bbbbbb;
					}
				}
			}
			@include max-screen(767px) {
				margin-bottom: 35px;
				.step {
					margin-bottom: 30px;
					.titleStep {
						margin: 0 -10px 24px;
						font-size: 14px;
						padding: 4px 0 3px 25px;
						margin-bottom: 16px;
					}
					.demoImg {
						margin-top: 17px;
						padding: 0 87px;
						img {

						}
					}
				}
				
			}
		}
		.delivery {
			margin-bottom: 55px;
			.textIntro {
				line-height: 1.8;
				letter-spacing: 1.3px;
			}
			.deliveryBox {
				.titleBox {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: bold;
					color: #1e1e1e;
					background: rgba(197, 217, 221, 0.3);
					padding: 4px 0 4px 20px;
					margin-bottom: 20px;
				}
				.detail {
					margin: 24px 0 35px;
				}
				.noted {
					margin-top: -25px;
					font-size: 12px;
				}
				.regionBox {
					margin-bottom: 42px;
					.regionBoxLeft {
						float: left;
						width: 49%;
						padding: 12px 20px;
						border: solid 1px #e8e8e9;
						h4 {
							font-size: 16px;
							font-weight: bold;
							color: #d22522;
							position: relative;
							padding-left: 25px;
							margin-bottom: 5px;
							letter-spacing: 1.8px;
							&:before {
								position: absolute;
								content: '';
								top: 50%;
								transform: translateY(-50%);
								-moz-transform: translateY(-50%);
								-webkit-transform: translateY(-50%);
								-ms-transform: translateY(-50%);
								left: 0;
								width: 17px;
								height: 17px;
								border: solid 2px #d12626;
								border-radius: 50%;
							}
						}
					}
					.regionBoxRight {
						float: right;
						width: 49%;
						padding: 12px 20px;
						border: solid 1px #e8e8e9;
						h4 {
							font-size: 16px;
							font-weight: bold;
							color: #1a2aa3;
							position: relative;
							padding-left: 25px;
							margin-bottom: 5px;
							letter-spacing: 1.8px;
							&:before {
								position: absolute;
								content: '';
								top: 50%;
								transform: translateY(-50%);
								-moz-transform: translateY(-50%);
								-webkit-transform: translateY(-50%);
								-ms-transform: translateY(-50%);
								left: 0;
								width: 17px;
								height: 2px;
								background: #1a2aa3;
								transform: rotate(45deg);
							}
							&:after {
								position: absolute;
								content: '';
								top: 50%;
								transform: translateY(-50%);
								-moz-transform: translateY(-50%);
								-webkit-transform: translateY(-50%);
								-ms-transform: translateY(-50%);
								left: 0;
								width: 17px;
								height: 2px;
								background: #1a2aa3;
								transform: rotate(-45deg);
							}
						}
					}
				}
			}
			@include max-screen(767px) {
				margin-bottom: 36px;
				.deliveryBox {
					.titleBox {
						margin: 0 -10px 20px;
						font-size: 14px;
						padding: 4px 0 3px 25px;
					}
					.detail {
						margin: 17px 0 25px;
					}
					.noted {
						font-size: 13px;
						margin-top: -17px;
						padding: 0 15px;
					}
					.regionBox {
						margin-bottom: 30px;
						.regionBoxLeft {
							width: 100%;
							float: none;
							padding: 8px 15px;
							margin-bottom: 5px;
							h4 {
								font-size: 14px;
								padding-left: 20px;
								&:before {
									width: 13px;
									height: 13px;
								}
							}
						}
						.regionBoxRight {
							width: 100%;
							float: none;
							padding: 8px 15px;
							h4 {
								font-size: 14px;
								padding-left: 20px;
								&:before {
									width: 13px;
									top: 40%;
								}
								&:after {
									width: 13px;
									top: 40%;
								}
							}
						}
					}
				}
			}
		}
		.paymentMethod {
			margin-bottom: 45px;
			.paymentBox {
				.titleBox {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: bold;
					color: #1e1e1e;
					background: rgba(197, 217, 221, 0.3);
					padding: 4px 0 4px 20px;
					margin-bottom: 22px;
				}
				.imgPayment {
					margin: 26px 0 42px;
				}
			}
			@include max-screen(767px) {
				margin-bottom: 35px;
				.paymentBox {
					.titleBox {
						margin: 0 -10px 20px;
						font-size: 14px;
						padding: 4px 0 3px 25px;
					}
					.imgPayment {
						padding: 0 15px;
						margin: 18px 0 29px;
					}
				}
			}
		}
		.about {
			margin-bottom: 44px;
			.aboutBox {
				margin-bottom: 37px;
				&:last-child {
					margin-bottom: 0;
				}
				.titleBox {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: bold;
					color: #1e1e1e;
					background: rgba(197, 217, 221, 0.3);
					padding: 4px 0 4px 20px;
					margin-bottom: 24px;
				}
				.detail {
					margin-bottom: 6px;
					letter-spacing: 1.4px;
					span {
						color: #d22522;
					}
					a {
						text-decoration: underline;
						color: #00bbcc;
					}
				}
				.noted {
					font-size: 12px;
					line-height: 2;
					letter-spacing: 1.2px;
					a {
						text-decoration: underline;
						color: #00bbcc;
					}
				}
			}
			@include max-screen(767px) {
				margin-bottom: 30px;
				.aboutBox {
					margin-bottom: 27px;
					.titleBox {
						margin: 0 -10px 18px;
						font-size: 14px;
						padding: 4px 0 3px 25px;
					}
					.detail {
						margin-bottom: 8px;
					}
					.noted {
						color: #4b4b4b;
						font-size: 13px;
						padding: 0 15px;
					}
				}
			}
		}
		.courses {
			margin-bottom: 53px;
			.textIntro {
				line-height: 1.8;
				letter-spacing: 1.2px;
			}
			.courseBox {
				margin-bottom: 34px;
				&:last-child {
					margin-bottom: 0;
				}
				.courseBoxCol {
					float: left;
					width: 31%;
					margin-right: 3.5%;
					margin-top: 10px;
					&:last-child {
						margin-right: 0;
					}
					h6 {
						font-size: 14px;
						font-weight: bold;
						color: #00bbcc;
					}
					p {
						letter-spacing: 1.5px;
					}
				}
				.titleBox {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: bold;
					color: #1e1e1e;
					background: rgba(197, 217, 221, 0.3);
					padding: 4px 0 4px 20px;
					margin-bottom: 15px;
				}
				.detail {
					margin-bottom: 19px;
					letter-spacing: 1.3px;
				}
				.noted {
					font-size: 12px;
					letter-spacing: 1.1px;
					line-height: 2;
				}
			}
			@include max-screen(767px) {
				margin-bottom: 40px;
				.courseBox {
					margin-bottom: 0;
					.titleBoxSP {
						margin: 0 -10px 0;
						font-size: 14px;
						padding: 10px 0 10px 25px;
						color: #fff;
						background: #1e1e1e;
						position: relative;
						&:after {
							position: absolute;
							content: '';
							height: 3px;
							width: 13px;
							background: #fff;
							top: 50%;
							right: 18px;
							transform: translateY(-50%);
							-moz-transform: translateY(-50%);
							-webkit-transform: translateY(-50%);
							-ms-transform: translateY(-50%);
						}
					}
					.courseContent {
						margin-top: 18px;
						display: none;
						padding: 0 15px;
						margin-bottom: 15px;
					}
					.unactivated {
						background: #f6f6f6;
						color: #1e1e1e;
						margin-bottom: 0;
						border-top: solid 1px #bbbbbb;
						border-bottom: solid 1px #bbbbbb;
						&:after {
							position: absolute;
							content: '';
							height: 3px;
							width: 13px;
							background: #a1b2ba;
							top: 50%;
							right: 18px;
							transform: translateY(-50%);
							-moz-transform: translateY(-50%);
							-webkit-transform: translateY(-50%);
							-ms-transform: translateY(-50%);
						}
						&:before {
							position: absolute;
							content: '';
							height: 13px;
							width: 3px;
							background: #a1b2ba;
							top: 50%;
							right: 23px;
							transform: translateY(-50%);
							-moz-transform: translateY(-50%);
							-webkit-transform: translateY(-50%);
							-ms-transform: translateY(-50%);
						}
					}
					.courseBoxCol {
						width: 100%;
						float: none;
						margin-top: 10px;
						padding-bottom: 10px;
						border-bottom: solid 1px #bbbbbb;
						&:first-child {
							margin-top: 0;
						}
						&:last-child {
							border-bottom: 0;
						}
					}
					.detail {
						margin-bottom: 10px;
						padding: 0;
					}
					.noted {
						// padding: 0 15px;
					}
					.activated {
						display: block;
					}
				}
				// .courseBoxSP {
				// 	margin-bottom: 0;
				// }
			}
		}
		.services {
			margin-bottom: 96px;
			@include max-screen(767px) {margin-bottom: 50px;}
			.textIntro {
				line-height: 1.8;
				letter-spacing: 1.3px;
			}
		}
	}
}