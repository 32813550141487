.guideline {
	#mainContent {
		margin-top: 5px;
		.infoSec {
			max-width: 800px;
			margin: 0 auto;
			table {
				margin-bottom: 45px;
				tbody {
					tr {
						th {
							text-align: left;
							width: 18.8%;
							letter-spacing: 2px;
						}
						td {
							width: 81.2%;
							margin-bottom: 25px;
							display: block;
							letter-spacing: 1.6px;
						}
						&:last-child {
							td {
								margin-bottom: 0;	
							}
						}
					}
					
				}
			}
		}
		.paymentSec {
			margin: 0 -10px;
			background: #f6f6f6;
			padding: 45px 0 40px;
			.paymentContent {
				max-width: 795px;
				margin: 0 auto;
				h3 {
					font-weight: bold;
					display: block;
					border-bottom: solid 1px #8e8e8e;
					margin-bottom: 10px;
					line-height: 1.6;
					letter-spacing: 0;
				}
				.introText {
					margin-bottom: 3px;
				}
				.paymentBox {
					margin-bottom: 27px;
					&:last-child {
						margin-bottom: 0;
					}
					h4 {
						line-height: 2;
					}
					p {
						line-height: 2;
					}
				}
			}
		}
		.transportSec {
			background: #fff;
		}
	}
	@include max-screen(767px) {
		#mainContent {
			.infoSec {
				padding: 0 15px;
				table {
					margin-bottom: 36px;
					tbody {
						tr {
							th {
								display: block;
								width: 100%;
								border-bottom: solid 1px #8e8e8e;
								margin-bottom: 5px;
								line-height: 2.1;
							}
							td {
								width: 100%;
								margin-bottom: 15px;
							}
						}
					}
				}
			}
			.paymentSec {
				padding: 15px 0 8px;
				.paymentContent {
					padding: 0 25px;
					h3 {
						line-height: 2.2;
					}
					.paymentBox {
						margin-bottom: 25px;
					}
				}
			}
		}
		
	}
}