@charset 'UTF-8';

.top {
	// background: url(../img/top/top_ol.jpg) center -4px no-repeat;
	// > * {opacity: 0.5;}
	@include max-screen(767px) {
		footer {margin-top: 0;}
	}
}

.top .visual {
	max-height: 620px;
	overflow: hidden;
	margin-bottom: 50px;
	text-align: center;
	position: relative;
	.vSlider {
		position: relative;
		li {
			background-image: url(../img/top/bg_slide1.jpg);
			background-repeat: no-repeat;
			background-position: center center;
			@include bgsize(cover);
			text-align: center;
			img { display: inline-block; }
		}
		li:nth-child(1) { background-image: url(../img/top/bg_slide1.jpg); }
		li:nth-child(2) { background-image: url(../img/top/bg_slide1.jpg); }
	}
	.thumbs {
		background: #eff5f5;
		padding: 20px 10px;
		text-align: center;
	}
	.vThumb {margin-left: -6.5px; margin-right: -6.5px;
		@include max-screen(767px) {margin: 0;}
		li {
			display: inline-block;
			vertical-align: top;
			width: 25%;
			padding: 0 6.5px;
			img { display: block; @include opacity(50); width: 100%; }
		}
		.slick-current, .slick-active {
			img { @include opacity(100); }
			a:hover img {opacity: 0.8;}
		}
	}
	.slick-arrow {
		position: absolute;
		left: 0;
		top: 40%;
		z-index: 20;
		width: 60px;
		height: 60px;
		color: #8e8e8e;
		font-size: 20px;
		cursor: pointer;
		background: url(../img/common/icon/ico_arrow2.svg) no-repeat center center;
		@include bgsize(28px 43px);
		&:hover {opacity: 0.8;}
	}
	.slick-next {
		left: auto;
		right: 0;
		@include rotate(180deg);
	}
	@include max-screen(767px) {
		max-height: 700px;
		margin-bottom: 0;
		.thumbs {
			padding: 10px;
		}
		.vThumb {
			li {
				width: 50%;
				padding: 0 7px;
				img { width: 100%; }
			}
			.slick-current, .slick-active {
				img { @include opacity(100); }
			}
		}
		.slick-arrow {
			top: 123%;
			width: 20px;
			height: 20px;
			background-image: url(../img/common/icon/ico_arrow1SP.svg);
			@include bgsize(9px 19px);
		}
		.slick-next {right: -3px;}
		.slick-prev {left: -3px;}
	}
	@include max-screen(767px) {
		max-height: 440px;
	}
}

.top .item_search {
	background: $colortxt1;
	padding: 20px 25px;
	margin: 0 -10px 35px;
	.form_search {
		margin: 0;
		display: block;
		width: 100%;
		position: relative;
		.txt_search {
			border: 1px solid #bbb;
			height: 35px;
			padding: 5px 70px 5px 10px;
			width: 100%;
		}
		.btn_search {
			position: absolute;
			right: 0;
			top: 0;
			width: 65px;
			height: 100%;
			font-size: 14px;
			font-weight: bold;
			color: #fff;
			background: $colortext;
			text-indent: inherit;
			letter-spacing: 2px;
			line-height: 35px;
			padding-left: 25px;
			&:before {
				content: '';
				position: absolute;
				left: 7px;
				top: 50%;
				width: 15px;
				height: 16px;
				margin-top: -8px;
				background: url(../img/common/icon/ico_search_grey.svg) no-repeat 50% 50%;
				@include bgsize(15px 16px);
			}
		}
	}
}

.top .tabSec {
	overflow: hidden;
	.tabBox {
		.tab_list {
			margin: 0 0 20px;
			text-align: center;
			.tab {
				float: left;
				width: 20%;
				padding: 0 2px 4px;
				color: #fff;
				line-height: 1.4;
				&.large { width: 40%; }
				a {
					display: block;
					color: #fff;
					background: #d2d2d2;
					padding: 8px 5px 6px;
					position: relative;
					// white-space: nowrap;
					@include notransition;
					@include radius(50px);
					&:hover {
						@include notransition;
						@include opacity(80);
					}
				}
				&.active, &.slick-current {
					color: #fff;
					a {
						background: $colortxt1;
					}
				}
			}
		}
	}
	@include max-screen(767px) {
		border-bottom: 1px solid $colortext;
		margin: 0 -10px 40px;
		.tabBox {
			.tab_info {
				border-top: 1px solid #bbb;
				.info {
					display: block;
					float: none;
					position: static !important;
					left: auto;
					top: auto;
					opacity: 1 !important;
					.accorLine {
						font-size: 14px;
						background: #f6f6f6;
						border-bottom: 1px solid #bbb;
						padding: 11px 25px 8px;
						cursor: pointer;
						position: relative;
						&.active {
							background: $colortext;
							color: #fff;
							a { color: #fff; }
							&:before { background: #fff; }
						}
					}
					.accorBox {
						display: none;
					}
					&:first-child {
						.accorBox {
							display: block;
						}
					}
					&:last-child {
						.accorLine {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}

.top .voiceSec {
	background: #eff5f5;
	padding: 50px 35px 60px;
	margin: 0 -10px;
	.secInner {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left: -10px;
			top: 0;
			z-index: 10;
			width: 10px;
			height: 100%;
			background: #eff5f5;
		}
		&:after {
			content: '';
			position: absolute;
			right: -10px;
			top: 0;
			z-index: 10;
			width: 10px;
			height: 100%;
			background: #eff5f5;
		}
	}
	.slick-arrow {
		position: absolute;
		left: -30px;
		top: 40%;
		z-index: 20;
		width: 40px;
		height: 40px;
		color: #8e8e8e;
		font-size: 20px;
		cursor: pointer;
		background: url(../img/common/icon/ico_arrow1.svg) no-repeat center center;
		@include bgsize(10px 17px);
		&:hover {opacity: 0.5;}
	}
	.slick-next {
		left: auto;
		right: -30px;
		@include rotate(180deg);
	}
	@include max-screen(767px) {
		background: #fff;
		padding: 0 20px 40px;
		.secInner {
			position: relative;
			&:before, &:after { display: none; }
		}
		.slick-arrow {
			background-image: url(../img/common/icon/ico_arrow1SP.svg);
			@include bgsize(8px 11px);
		}
	}
}

.top .newsSec {
	clear: both;
	padding: 45px 0 0;
	.sHead {
		font-size: 22px;
		letter-spacing: 2px;
		text-align: left;
		margin-bottom: 10px;
	}
	.news_list {
		li {
			letter-spacing: 0.7px;
			border-bottom: 1px solid #d2d2d2;
			padding: 12px 0 10px;
			a {
				display: block;
			}
			.date {
				display: block;
				float: left;
				color: #787878;
				font-size: 12px;
				width: 95px;
			}
			.desc {
				display: block;
				line-height: 1.7;
				overflow: hidden;
			}
		}
	}
	@include max-screen(767px) {
		background: #eff5f5;
		padding: 35px 0 20px;
		margin: 0 -10px;
		.sHead {
			font-size: 20px;
			text-align: center;
			margin-bottom: 5px;
		}
		.news_list {
			li {
				border-bottom: 1px solid #8e8e8e;
				padding: 14px 20px;
				.date {
					float: none;
					width: 100%;
				}
				.desc {
					font-size: 14px;
					line-height: 1.5;
					text-decoration: underline;
				}
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}

.slick-track {
	padding-left: 0;
}